import { Lightbulb } from "@phosphor-icons/react";
import { Link } from "react-router-dom";

export const FileFootnote = (
  <div className="text-sm text-gray-500 flex gap-2 items-start pb-5">
    <Lightbulb size={40} className="text-blue-500" /> Remember, for any documents you upload, please include full-page screenshots with visible URLs. This helps authenticate your achievements!
  </div>
)

export const SignatoryFootnote = (
  <div className="text-sm text-gray-500 flex gap-2 items-start pb-5">
    <Lightbulb size={40} className="text-blue-500" /> <p>We recommend reaching out to your signatory in advance, to ensure they can sign your reference letter. We’ve prepared this <Link rel="nofollow noopener noreferrer" className="text-blue-600 hover:underline" target="_blank" to="https://docs.google.com/document/d/14eZR2VRrEyMgLyy2w5MyiHfb5Z3NXQIs6uvexG2V_YE/edit?usp=sharing">template guide</Link> to help with outreach.</p>
  </div>
)