import { withAuthenticationRequired } from "@auth0/auth0-react";
import { ComponentType } from "react";
import Spinner from "./spinner";

export const AuthenticationGuard = (props: { component: ComponentType }) => {
  const Component = withAuthenticationRequired(props.component, {
    onRedirecting: () => (
      <div className="flex flex-col items-center justify-center h-screen gap-y-2 bg-[url(/bg-grid.svg)]">
        <Spinner />
        <p className="text-sm text-muted-foreground">
          Logging in, please wait...
        </p>
      </div>
    ),
  });

  return <Component />;
};
