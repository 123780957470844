export const moduleDefaultNotes: {
  [key: string]: string
} = {
  'memo-starter': `U.S. Petitioner:
Petitioner Funding:
Field of endeavor:
Filing Type  — Consular or Change of Status:
Business License or Tax Return for companies over a year old:
U.S. Role:
Current Status:
Expiry for Current Status:
Salary for U.S. Role:
Equity ownership for U.S. Role:
Advisory Opinion Letter Signatory:
Criteria Summary and Strength:
`
}