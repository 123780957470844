import { useAuth0 } from "@auth0/auth0-react";
import Spinner from "@components/spinner";
import { isUserAdminOverridden } from "@utils/admin";
import { useEffect } from "react";
import { useLocation, useNavigate } from "react-router-dom";

import * as Sentry from "@sentry/react";
import { useContact } from "@utils/hooks";

const Login = () => {
  const { loginWithRedirect, isAuthenticated, user } = useAuth0();
  const { admin } = useContact();
  const navigate = useNavigate();
  const { state } = useLocation();
  const returnTo = state?.returnTo ?? "/";

  loginWithRedirect({
    authorizationParams: {
      redirect_uri: `${window.location.origin}/callback`,
    },
    appState: {
      returnTo,
    },
  });

  useEffect(() => {
    if (isAuthenticated) {
      if (user == null) {
        navigate(returnTo);
        return;
      }

      Sentry.setUser({
        id: user.sub,
        name: user.name,
        email: user.email,
      });

      if (admin && !isUserAdminOverridden()) {
        navigate("/admin");
        return;
      }

      navigate(returnTo);
    }
  }, [admin, isAuthenticated, navigate, returnTo, state, user]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Spinner text="Loading user details..." />
    </div>
  );
};

export default Login;
