export enum UploadStatus {
  Ready,
  Uploading,
  Success,
  ErrorGdrive,
  ErrorApollo,
  ErrorSelect,
  ErrorExhibitFetch,
  ErrorBackend,
}

export const downloadFileFromResponse = async (response: Response) => {
  const getFileName = (response: Response) => {
    const header = response.headers.get("Content-Disposition") ?? "";
    const mimeType = response.headers.get("Content-Type") ?? "";

    const parts = header.split(";");

    if (parts.length < 2) {
      const ext = mimeType.split("/")[1];
      return `file.${ext}`;
    }
    const filename = parts[1].split("=")[1].replaceAll('"', "");
    return filename;
  };

  const filename = getFileName(response);

  const blob = await response.blob();
  const url = window.URL.createObjectURL(blob);
  const a = document.createElement("a");
  a.download = filename;
  a.href = url;
  a.click();
};
