import { onboardingMachine, OnboardingContext } from '@pages/onboarding/beneficiary/machines';
import * as React from 'react';
import { createActorContext } from '@xstate/react'

export const BeneficiaryOnboardingContext = createActorContext(onboardingMachine)
export const OnboardingStateMachineProvider = ({ children }: { children: React.ReactNode }) => {
  return (
    <BeneficiaryOnboardingContext.Provider>
      {children}
    </BeneficiaryOnboardingContext.Provider>
  );
};

export const useStateMachine = () => BeneficiaryOnboardingContext;
