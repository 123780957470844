import {
  useAllReusableExhibitsQuery,
  useCreateReusableExhibitEntityMutation,
  useDeleteReusableExhibitEntityByReusableExhibitIdAndEntityIdMutation,
  useExhibitRepoQueryQuery,
  useReusableExhibitByIdQuery,
  useUpdateResuableExhibitMutation,
  useUpdateReusableExhibitFilesOrderMutation,
  useUpdateReusableSubexhibitOrderMutation,
} from "@codegen/index";
import {
  EntityTypes,
  UpdateMultipleReusableExhibitsOrderInput,
  UpdateReusableExhibitInput,
} from "@codegen/schema";
import BuilderNavigator from "@components/adminPageNavigator";
import { ExhibitOwnerJellyBean } from "@components/builder/jellybean";
import { Button } from "@components/button";
import { Combobox } from "@components/combobox";
import EntitySelector from "@components/entitySelector";
import ExhibitTypeSelectorMemo from "@components/exhibitTypeSelector";
import FileViewerModal from "@components/fileViewModal";
import { Input } from "@components/input";
import ModuleTypeSelectorMemo from "@components/moduleTypeSelector";
import Spinner from "@components/spinner";

import MemoizedRichTextEditor from "@components/tiptapEditor/richTextEditor";
import {
  DndContext,
  DragEndEvent,
  MouseSensor,
  useSensor,
  useSensors,
} from "@dnd-kit/core";
import { SortableContext, arrayMove, useSortable } from "@dnd-kit/sortable";
import {
  ArrowSquareOut,
  DotsSixVertical,
  Eye,
  EyeSlash,
  MagnifyingGlass,
  PencilSlash,
  X,
} from "@phosphor-icons/react";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@radix-ui/react-tooltip";
import { cn } from "@utils/cn";

import { useCallback, useEffect, useMemo, useState } from "react";

const ExhibitRepoSidebar = (props: {
  selectedExhibit?: {
    id: string;
    name?: string;
  };
  onSelectExhibit: (exhibit: { id: string }) => void;
  onClickCreate: () => void;
  moduleTypeFilter: string;
  exhibitTypeFilter: string;
}) => {
  const {
    selectedExhibit,
    onSelectExhibit,
    moduleTypeFilter,
    exhibitTypeFilter,
  } = props;

  const { data } = useAllReusableExhibitsQuery({
    pollInterval: 5000,
  });
  const [searchQuery, setSearchQuery] = useState<string>("");

  const searchedExhibits = useMemo(() => {
    if (data?.allReusableExhibits?.nodes == null) return [];

    const res = [];

    for (const exhibit of data?.allReusableExhibits?.nodes) {
      if (exhibit == null) continue;
      if (exhibit.moduleTypeByModuleType == null) continue;
      if (exhibit.exhibitTypeByExhibitType == null) continue;

      if (
        moduleTypeFilter !== "all" &&
        exhibit.moduleTypeByModuleType.id !== moduleTypeFilter
      )
        continue;

      if (
        exhibitTypeFilter !== "all" &&
        exhibit.exhibitTypeByExhibitType.id !== exhibitTypeFilter
      )
        continue;

      if (
        searchQuery.trim() !== "" &&
        !exhibit.name.toLowerCase().includes(searchQuery.toLowerCase())
      )
        continue;

      res.push(exhibit);
    }

    return res;
  }, [data, exhibitTypeFilter, moduleTypeFilter, searchQuery]);

  return (
    <div className="resize-x w-1/2 h-full border-x border-b overflow-auto">
      <div className="pl-4 flex flex-row w-full h-[50px] items-center gap-x-2 border-b">
        <MagnifyingGlass className="text-gray-400" />
        <input
          className="bg-transparent w-full"
          defaultValue={searchQuery}
          onChange={(e) => setSearchQuery(e.target.value)}
        />
        {/* <Button
          variant={"secondary"}
          size={"icon"}
          className="bg-white h-fit w-fit p-2 rounded-sm ml-auto mr-2"
          onClick={onClickCreate}
        >
          <Plus size={12} />
        </Button> */}
      </div>
      <div className="h-full overflow-auto bg-white">
        {searchedExhibits.length === 0 && (
          <div className="flex flex-col items-center justify-center h-full">
            <h1 className="text-lg font-semibold mb-1">No exhibits found</h1>
            <p className="text-sm text-gray-400">
              Try changing your filters or search query
            </p>
          </div>
        )}
        {searchedExhibits.map((exhibit) => (
          <div
            key={`${exhibit.id}`}
            className={
              "flex pl-4 py-3 border-b items-center hover:cursor-pointer hover:bg-gray-50" +
              (selectedExhibit?.id === exhibit.id ? " bg-gray-50" : "")
            }
            onClick={() => onSelectExhibit({ id: exhibit.id })}
          >
            <div className="flex flex-col items-center gap-x-2 w-full pr-5">
              <div className="flex flex-col w-full">
                <div className="flex flex-row w-full items-center">
                  <h1 className="text-lg font-semibold mb-1">
                    {exhibit?.name}
                  </h1>
                </div>

                <p className="text-sm text-gray-400 w-full overflow-hidden text-clip max-h-[40px]">
                  <div
                    dangerouslySetInnerHTML={{
                      __html:
                        exhibit?.description == null ||
                        exhibit.description.trim() === ""
                          ? "No description provided"
                          : exhibit?.description,
                    }}
                    className="overflow-hidden text-clip text-gray-400 text-sm"
                  />
                </p>
              </div>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

const FileComponent = (props: {
  id: string;
  name: string;
  onClick: () => void;
}) => {
  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform } =
    useSortable({
      id: props.id,
    });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  return (
    <div
      className="rounded rounded-sm bg-gray-100 flex flex-row items-center gap-x-2 w-full px-4 py-2 hover:cursor-pointer hover:bg-gray-50"
      ref={setNodeRef}
      style={style}
      onClick={props.onClick}
    >
      <Button
        variant="handle"
        size="handle"
        ref={setActivatorNodeRef}
        {...attributes}
        {...listeners}
        className="p-2"
      >
        <DotsSixVertical size={14} color="gray" />
      </Button>

      <h1 className="text-sm text-accent">{props.name}</h1>
    </div>
  );
};

const ExhibitRepoEditor = (props: {
  exhibitId: string;
  subexhibit?: boolean;
}) => {
  const { exhibitId, subexhibit: isSubexhibit = false } = props;

  const [descriptionEditorOpen, setDescriptionEditorOpen] = useState(false);
  const mouseSensor = useSensor(MouseSensor, {
    activationConstraint: {
      distance: 10,
    },
  });

  const [linkExhibitToEntityMutation] =
    useCreateReusableExhibitEntityMutation();

  const [unlinkExhibitFromEntityMutation] =
    useDeleteReusableExhibitEntityByReusableExhibitIdAndEntityIdMutation();

  const { attributes, listeners, setNodeRef, setActivatorNodeRef, transform } =
    useSortable({
      id: exhibitId,
    });

  const style = transform
    ? {
        transform: `translate3d(${transform.x}px, ${transform.y}px, 0)`,
      }
    : undefined;

  const sensors = useSensors(mouseSensor);

  const { data, loading, refetch } = useReusableExhibitByIdQuery({
    variables: {
      id: exhibitId,
    },
  });

  const [subexhibits, setSubexhibits] = useState<string[]>();
  const [files, setFiles] = useState<
    {
      id: string;
      name: string;
    }[]
  >();

  const [updateReusableExhibit] = useUpdateResuableExhibitMutation();
  const [updateSubexhibitOrder] = useUpdateReusableSubexhibitOrderMutation();
  const [updateFileOrder] = useUpdateReusableExhibitFilesOrderMutation();

  const [fileViewerOpen, setFileViewerOpen] = useState<boolean>(false);
  const [selectedFileId, setSelectedFileId] = useState<string>();

  useEffect(() => {
    if (subexhibits != null) return;
    if (
      data?.reusableExhibitById?.reusableExhibitsByParentExhibitId?.nodes ==
      null
    )
      return;

    const res = [];
    for (const subExhibit of data?.reusableExhibitById
      ?.reusableExhibitsByParentExhibitId?.nodes) {
      if (subExhibit == null) continue;

      res.push(subExhibit.id);
    }
    setSubexhibits(res);
  }, [
    data?.reusableExhibitById?.reusableExhibitsByParentExhibitId?.nodes,
    subexhibits,
  ]);

  useEffect(() => {
    if (files != null) return;
    if (
      data?.reusableExhibitById?.reusableExhibitFilesByReusableExhibitId
        ?.nodes == null
    )
      return;

    const res = [];
    for (const file of data?.reusableExhibitById
      ?.reusableExhibitFilesByReusableExhibitId?.nodes) {
      if (file == null) continue;
      if (file.fileByFileId == null) continue;

      res.push({
        id: file.fileByFileId?.id,
        name: file.fileByFileId?.name,
      });
    }
    setFiles(res);
  }, [
    data?.reusableExhibitById?.reusableExhibitFilesByReusableExhibitId?.nodes,
    files,
  ]);

  const fileContainer = useMemo(() => {
    const resolvedFiles = files ?? [];

    const onFileDragEnd = async (event: DragEndEvent) => {
      const { active, over } = event;
      if (over == null) return;
      if (active.id === over.id) return;

      const allFiles = resolvedFiles;

      if (allFiles == null || allFiles.length === 0) return;

      const oldIndex = allFiles.findIndex((child) => child.id === active.id);
      const newIndex = allFiles.findIndex((child) => child.id === over.id);

      const oldChildren = allFiles;
      const newChildren = arrayMove(oldChildren, oldIndex, newIndex);
      setFiles(newChildren);

      const { errors } = await updateFileOrder({
        variables: {
          input: {
            inputData: allFiles.map((child, idx) => ({
              reusableExhibitId: exhibitId,
              fileId: child.id,
              orderIndex: idx,
            })),
          },
        },
      });
      refetch();

      if (errors != null) {
        console.error(errors);

        setFiles((prev) => {
          if (prev == null) return prev;
          return oldChildren;
        });
      }
    };

    return (
      <div className="flex flex-col">
        {fileViewerOpen && selectedFileId && (
          <FileViewerModal
            fileId={selectedFileId}
            onClose={() => {
              setFileViewerOpen(false);
              setSelectedFileId(undefined);
            }}
          />
        )}

        <h1 className="text-sm text-gray-400 font-semibold pl-4">Files</h1>
        <div className="flex flex-col flex flex-row gap-y-2 w-full rounded-sm items-center mt-1">
          <DndContext sensors={sensors} onDragEnd={onFileDragEnd}>
            <SortableContext items={resolvedFiles.map((x) => x.id)}>
              {resolvedFiles.length === 0 && (
                <div className="bg-gray-100 w-full pl-4 py-2 rounded rounded-sm">
                  <p className="text-sm text-gray-400">No files provided</p>
                </div>
              )}
              {resolvedFiles.map((file) => (
                <FileComponent
                  key={file.id}
                  id={file.id}
                  name={file.name ?? ""}
                  onClick={() => {
                    setSelectedFileId(file.id);
                    setFileViewerOpen(true);
                  }}
                />
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </div>
    );
  }, [
    exhibitId,
    fileViewerOpen,
    files,
    refetch,
    selectedFileId,
    sensors,
    updateFileOrder,
  ]);

  const doLinkEntity = useCallback(async ({ id }: { id: string }) => {
    await linkExhibitToEntityMutation({
      variables: {
        input: {
          reusableExhibitEntity: {
            entityId: id,
            reusableExhibitId: exhibitId,
          },
        },
      },
    });

    await refetch();
  }, []);

  const doUnlinkEntity = useCallback(
    async (entityId: string) => {
      await unlinkExhibitFromEntityMutation({
        variables: {
          input: {
            entityId: entityId,
            reusableExhibitId: exhibitId,
          },
        },
      });

      await refetch();
    },
    [exhibitId, refetch, unlinkExhibitFromEntityMutation]
  );

  const entities = useMemo(() => {
    if (
      data?.reusableExhibitById?.reusableExhibitEntitiesByReusableExhibitId
        ?.nodes == null
    )
      return;

    const res = [];
    for (const entity of data?.reusableExhibitById
      ?.reusableExhibitEntitiesByReusableExhibitId?.nodes) {
      if (entity?.additionalEntityByEntityId == null) continue;

      res.push({
        id: entity.additionalEntityByEntityId.id,
        name:
          entity.additionalEntityByEntityId.type === EntityTypes.Company
            ? entity.additionalEntityByEntityId.companyByCompanyId?.dbaName
            : entity.additionalEntityByEntityId.type === EntityTypes.User
            ? entity.additionalEntityByEntityId.userByUserId?.fullName
            : entity.additionalEntityByEntityId.name,
        type: entity.additionalEntityByEntityId.type
      });
    }

    return (
      <div className="flex flex-col">
        <div className="flex flex-row gap-x-2">
          <h1 className="text-sm text-gray-400 font-semibold pl-4">Entities</h1>
          <EntitySelector onSelect={doLinkEntity} />
        </div>
        <div className="flex flex-col flex flex-row gap-y-2 w-full rounded-sm items-center mt-1">
          {res.length === 0 && (
            <div className="bg-gray-100 w-full pl-4 py-2 rounded rounded-sm">
              <p className="text-sm text-gray-400">No entities provided</p>
            </div>
          )}

          {res.map((entity) => (
            <div
              className="rounded rounded-sm bg-gray-100 flex flex-row items-center gap-x-2 w-full px-4 py-2 text-sm text-gray-400"
              key={entity.id}
            >
              <h1>
                {entity.id}. {entity.name}&nbsp;({entity.type})
              </h1>
              <div className="flex flex-row gap-x-2 items-center ml-auto">
                <ArrowSquareOut size={16} className="ml-auto" />
                <Button
                  variant="secondary"
                  size="icon"
                  className="ml-auto bg-transparent h-fit w-fit p-0 rounded-sm text-gray-400 shadow-none"
                  onClick={() => doUnlinkEntity(entity.id)}
                >
                  <X size={12} />
                </Button>
              </div>
            </div>
          ))}
        </div>
      </div>
    );
  }, [
    data?.reusableExhibitById?.reusableExhibitEntitiesByReusableExhibitId
      ?.nodes,
    data?.reusableExhibitById?.reusableExhibitsByParentExhibitId?.nodes,
    doLinkEntity,
  ]);

  const doSetKey = useCallback(
    async (
      key: keyof UpdateReusableExhibitInput["reusableExhibitPatch"],
      value: string | boolean
    ) => {
      if (data?.reusableExhibitById == null) return;

      const { errors } = await updateReusableExhibit({
        variables: {
          input: {
            id: data.reusableExhibitById.id,
            reusableExhibitPatch: {
              [key]: value,
            },
          },
        },
      });
      if (errors != null) {
        console.error(errors);
      }

      refetch();
    },
    [data, refetch, updateReusableExhibit]
  );

  const subExhibitContainer = useMemo(() => {
    if (subexhibits == null) return null;
    if (isSubexhibit) return null;

    const onSubExhibitDragEnd = async (event: DragEndEvent) => {
      const { active, over } = event;
      if (over == null) return;
      if (active.id === over.id) return;

      const allSubExhibits = subexhibits;

      if (allSubExhibits == null || allSubExhibits.length === 0) return;

      const oldIndex = allSubExhibits.findIndex((child) => child === active.id);
      const newIndex = allSubExhibits.findIndex((child) => child === over.id);

      const oldChildren = allSubExhibits;
      const newChildren = arrayMove(oldChildren, oldIndex, newIndex);
      setSubexhibits(newChildren);

      const { errors } = await updateSubexhibitOrder({
        variables: {
          input: {
            inputData: newChildren.map((child, idx) => ({
              reusableExhibitId: child,
              orderIndex: idx,
            })),
          } as UpdateMultipleReusableExhibitsOrderInput,
        },
      });

      if (errors != null) {
        console.error(errors);

        setSubexhibits((prev) => {
          if (prev == null) return prev;
          return oldChildren;
        });
      }
      refetch();
    };

    return (
      <div className="flex flex-col">
        <h1 className="text-sm text-gray-400 font-semibold pl-4">
          Subexhibits
        </h1>

        <div className="mt-1 rounded-sm">
          <DndContext sensors={sensors} onDragEnd={onSubExhibitDragEnd}>
            <SortableContext items={subexhibits}>
              {subexhibits.length === 0 && (
                <div className="bg-gray-100 w-full pl-4 py-2 rounded rounded-md">
                  <p className="text-sm text-gray-400">No subexhibits found</p>
                </div>
              )}
              {subexhibits.map((subExhibit) => (
                <div
                  key={subExhibit}
                  className="flex flex-row gap-x-4 w-full rounded-sm items-center"
                >
                  <ExhibitRepoEditor exhibitId={subExhibit} subexhibit={true} />
                </div>
              ))}
            </SortableContext>
          </DndContext>
        </div>
      </div>
    );
  }, [isSubexhibit, refetch, sensors, subexhibits, updateSubexhibitOrder]);

  return (
    <div
      className="h-full w-full"
      ref={setNodeRef}
      style={style}
      key={exhibitId}
    >
      {loading && (
        <div className="w-full h-full flex flex-col items-center justify-center">
          <Spinner />
        </div>
      )}
      {data && (
        <div className="w-full">
          <div
            className={
              "border-b py-2 px-4 bg-gray-100 mb-2 flex flex-row gap-x-2 items-center" +
              (isSubexhibit ? " rounded-tl-sm rounded-tr-sm" : "")
            }
          >
            {isSubexhibit && (
              <Button
                variant="handle"
                size="handle"
                ref={setActivatorNodeRef}
                {...attributes}
                {...listeners}
                className="p-2"
              >
                <DotsSixVertical size={14} color="gray" />
              </Button>
            )}

            <Input
              defaultValue={data.reusableExhibitById?.name}
              onChange={(e) => doSetKey("name", e.target.value)}
              className="text-lg font-semibold"
            />

            <TooltipProvider>
              <Tooltip>
                <TooltipTrigger>
                  <div
                    className={cn(
                      "flex font-bold text-md h-8 px-3 items-center rounded-sm",
                      data.reusableExhibitById?.visible
                        ? "bg-gray-100"
                        : "hover:bg-gray-100"
                    )}
                    onClick={
                      data.reusableExhibitById?.visible
                        ? () => doSetKey("visible", false)
                        : () => doSetKey("visible", true)
                    }
                  >
                    {data.reusableExhibitById?.visible ? (
                      <Eye size={18} weight="bold" />
                    ) : (
                      <EyeSlash size={18} weight="bold" />
                    )}
                  </div>
                </TooltipTrigger>
                <TooltipContent>Exhibit visible to customers</TooltipContent>
              </Tooltip>
            </TooltipProvider>
            <ExhibitOwnerJellyBean id={exhibitId} reusable />
          </div>

          <div className="flex flex-col p-4 w-full h-full overflow-scroll gap-y-3">
            <div className="grid gap-x-3 gap-y-3">
              <div className="bg-gray-100 flex flex-row gap-x-4 w-full rounded-sm items-center">
                <h1 className="text-sm text-gray-400 font-semibold pl-4">
                  Parent Module Type
                </h1>
                <ModuleTypeSelectorMemo
                  selectedType={
                    data?.reusableExhibitById?.moduleTypeByModuleType?.id ?? ""
                  }
                  onSelect={(x) => doSetKey("moduleType", x)}
                  className="ml-auto bg-white w-1/3"
                />
              </div>
            </div>
            <div className="grid gap-x-3 gap-y-3">
              <div className="bg-gray-100 flex flex-row gap-x-4 w-full rounded-sm items-center">
                <h1 className="text-sm text-gray-400 font-semibold pl-4">
                  Exhibit Type
                </h1>
                <ExhibitTypeSelectorMemo
                  moduleType={""}
                  selectedType={
                    data?.reusableExhibitById?.exhibitTypeByExhibitType?.id ??
                    ""
                  }
                  onSelect={(x) => doSetKey("exhibitType", x)}
                  className="ml-auto bg-white w-1/3"
                />
              </div>
            </div>
            <div className="flex flex-col">
              <h1 className="text-sm text-gray-400 font-semibold pl-4">
                Description
              </h1>

              {descriptionEditorOpen ? (
                <div className="border border-gray-100 rounded-md">
                  <MemoizedRichTextEditor
                    initialContent={data.reusableExhibitById?.description ?? ""}
                    onClose={() => setDescriptionEditorOpen(false)}
                  />
                </div>
              ) : (
                <div
                  className="bg-gray-100 gap-x-4 w-full rounded-sm items-center p-4 text-sm text-gray-400 mt-1 hover:cursor-pointer max-h-1/3"
                  onClick={() => {
                    setDescriptionEditorOpen(true);
                  }}
                  key={data.reusableExhibitById?.description}
                  dangerouslySetInnerHTML={{
                    __html: `
                          
                        ${
                          data.reusableExhibitById?.description == null ||
                          data.reusableExhibitById?.description.trim() === ""
                            ? "No description provided"
                            : data.reusableExhibitById?.description
                        }`,
                  }}
                />
              )}
            </div>
            {fileContainer}
            {!isSubexhibit && entities}
            {!isSubexhibit && <>{subExhibitContainer}</>}
          </div>
        </div>
      )}
    </div>
  );
};

const ExhibitRepo = () => {
  const [createReusableExhibitModalOpen, setCreateReusableExhibitModalOpen] =
    useState(false);

  const { data } = useExhibitRepoQueryQuery();

  const [moduleTypeFilter, setModuleTypeFilter] = useState<string>("all");
  const [exhibitTypeFilter, setExhibitTypeFilter] = useState<string>("all");

  const [selectedExhibit, setSelectedExhibit] = useState<{
    id: string;
    name?: string;
  }>();

  const moduleFilterOpts = useMemo(() => {
    if (data?.allModuleTypes == null) return [];

    const res = [
      {
        label: "All",
        value: "all",
      },
    ];

    for (const moduleType of data.allModuleTypes.nodes) {
      if (moduleType == null) continue;

      res.push({
        label: moduleType.name,
        value: moduleType.id,
      });
    }
    return (
      <Combobox
        options={res}
        defaultValue={moduleTypeFilter}
        onSelect={(x) => setModuleTypeFilter(x)}
        placeholder="Filter by Module Type"
      />
    );
  }, [data, moduleTypeFilter]);

  const exhibitFilterOpts = useMemo(() => {
    if (data?.allExhibitTypes == null) return [];

    const res = [
      {
        label: "All",
        value: "all",
      },
    ];

    for (const exhibitType of data.allExhibitTypes.nodes) {
      if (exhibitType == null) continue;

      res.push({
        label: exhibitType.name,
        value: exhibitType.id,
      });
    }
    return (
      <Combobox
        options={res}
        defaultValue={exhibitTypeFilter}
        onSelect={(x) => setExhibitTypeFilter(x)}
        placeholder="Filter by Exhibit Type"
      />
    );
  }, [data, exhibitTypeFilter]);

  return (
    <div className="h-screen w-screen flex flex-col p-4 bg-white">
      <BuilderNavigator />
      <div className="flex flex-row py-2 items-center border-b gap-x-2">
        <h1 className="text-sm text-gray-400 ml-4">Module Type</h1>
        <div className="flex gap-2 items-center">
          {moduleFilterOpts}
          <Button
            variant={"secondary"}
            size={"icon"}
            className="bg-white h-fit w-fit p-2 rounded-sm ml-auto mr-2"
            onClick={() => setModuleTypeFilter("all")}
          >
            <PencilSlash size={12} />
          </Button>
        </div>

        <h1 className="text-sm text-gray-400 ml-8">Exhibit Type</h1>
        <div className="flex gap-2 items-center">
          {exhibitFilterOpts}
          <Button
            variant={"secondary"}
            size={"icon"}
            className="bg-white h-fit w-fit p-2 rounded-sm ml-auto mr-2"
            onClick={() => setExhibitTypeFilter("all")}
          >
            <PencilSlash size={12} />
          </Button>
        </div>
      </div>

      <div className="flex h-full flex-row w-full overflow-hidden">
        <ExhibitRepoSidebar
          selectedExhibit={selectedExhibit}
          onSelectExhibit={(x) => setSelectedExhibit(x)}
          onClickCreate={() => setCreateReusableExhibitModalOpen(true)}
          moduleTypeFilter={moduleTypeFilter}
          exhibitTypeFilter={exhibitTypeFilter}
        />
        <div className="w-full h-full bg-white border-b border-r overflow-y-scroll">
          <ExhibitRepoEditor
            key={selectedExhibit?.id ?? ""}
            exhibitId={selectedExhibit?.id ?? ""}
          />
        </div>
      </div>
    </div>
  );
};

export default ExhibitRepo;
