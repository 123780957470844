import { localPersistedStorage } from "./persisted";

export const setUserAdminOverride = (id: string) => {
  localPersistedStorage.setItem("plymouth_admin_assume_role", id);
};

export const getUserAdminOverride = () => {
  return localPersistedStorage.getItem("overridingWsId");
};

export const clearUserAdminOverride = () => {
  localPersistedStorage.removeItem("overridingWsId");
};

export const isUserAdminOverridden = () => {
  return localPersistedStorage.getItem("overridingWsId") != null;
};
