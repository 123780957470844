import { useRef, useState } from 'react';
import { Button } from './button';
import { Camera, X, Headset } from '@phosphor-icons/react';
import { FormErrorMessage } from '@pages/onboarding/shared/error';
import { cn } from '@utils/cn';
import { useSupportContext } from '@providers/support';
import { format } from 'date-fns';
import { useContact } from '@utils/hooks';

export const SupportButton = () => {
  const videoRef = useRef(null);
  const { togglePanel, panelOpen } = useSupportContext()
  const [error, setError] = useState('');
  const [message, setMessage] = useState('');
  const [screenshotData, setScreenshotData] = useState(null);
  const [isSent, setSent] = useState(false)
  const { contact } = useContact()

  // Function to capture the screen
  const captureScreen = async () => {
    try {
      const stream = await navigator.mediaDevices.getDisplayMedia({
        video: true,
      });

      if (videoRef.current) {
        videoRef.current.srcObject = stream;
      }

      // Take a screenshot from the video stream
      const track = stream.getVideoTracks()[0];
      const imageCapture = new ImageCapture(track);
      const bitmap = await imageCapture.grabFrame();
      const canvas = document.createElement('canvas');
      canvas.width = bitmap.width;
      canvas.height = bitmap.height;
      const ctx = canvas.getContext('2d');
      ctx.drawImage(bitmap, 0, 0);
      setScreenshotData(canvas.toDataURL('image/png'));

      stream.getVideoTracks()[0].addEventListener('ended', () => {
        if (videoRef.current) {
          videoRef.current.srcObject = null;
        }
      });
    } catch (err) {
      setError('Failed to capture screen: ' + err.message);
    }
  };

  // Function to handle API submission
  const handleSubmit = async () => {
    try {

      if (!message) {
        throw new Error(`Please provide a brief message about your request.`)
      }

      const response = await fetch('https://hooks.slack.com/services/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', {
        method: 'POST',
        // headers: {
        //   // 'Content-Type': 'application/json',
        // },
        body: JSON.stringify({
          "blocks": [
            {
              "type": "section",
              "text": {
                "type": "mrkdwn",
                "text": "Customer submitted a new request"
              }
            },
            {
              "type": "section",
              "fields": [
                {
                  "type": "mrkdwn",
                  "text": `*Type:*\nSupport Popup`
                },
                {
                  "type": "mrkdwn",
                  "text": `*When:*\nSubmitted ${format(new Date(), 'MM/dd/yyyy')}`
                },
                {
                  "type": "mrkdwn",
                  "text": `*Customer ID:*\n${contact?.id}`
                },
                {
                  "type": "mrkdwn",
                  "text": `*Customer Name:*\n${contact?.name}`
                },
                {
                  "type": "mrkdwn",
                  "text": `*Message:*\n${message}`
                }
              ]
            },
            // {
            //   "type": "image",
            //   "title": {
            //     "type": "plain_text",
            //     "text": "image1",
            //     "emoji": true
            //   },
            //   "image_url": "https://api.slack.com/img/blocks/bkb_template_images/onboardingComplex.jpg",
            //   "alt_text": "image1"
            // },
            {
              "type": "divider"
            }
          ]
        }),
      });

      if (response.status === 200) {
        setSent(true)
      }
      // togglePanel();
    } catch (err) {
      setError(err.message);
    }
  };

  const sendAnother = () => {
    setSent(false)
    setError('')
    setMessage('')
    setScreenshotData(null)
  }

  return (
    <div className="fixed right-3 bottom-24">
      {/* Floating Button */}
      <button className="absolute right-0 bottom-0 w-12 h-12 rounded-full border shadow-md bg-gradient-to-br from-sky-100 via-rose-100 to-lime-100 text-primary font-semibold flex items-center justify-center" onClick={togglePanel}>
        {panelOpen ? <X size={20} weight='bold' /> : <Headset size={20} weight='bold' />}
      </button>

      {/* Modal */}
      {panelOpen && (
        <div className={cn("absolute bottom-14 right-0 w-96 p-3 bg-slate-50 rounded border animate-in ease-in slide-in-from-bottom-12")}>
          {
            isSent ? (
              <div>
                <h4 className="font-semibold">Thank you for your feedback!</h4>
                <p className="mb-2 text-sm">We have received your request and will get back to you as soon as possible.</p>
                <div className="flex justify-end">
                  <Button onClick={sendAnother}  className="">
                    Send another
                  </Button>
                </div>
              </div>
            ) : (
              <div className="">
                <h2 className="mb-2 font-semibold">Tell us a bit about what you need help with:</h2>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  placeholder="Enter an optional message to send"
                  className="w-full border rounded p-3"
                />
                {/* <h2 className="font-semibold">2. Capture a screenshot:</h2>
                <p className="text-sm text-gray-500 italic mb-2">This will be helpful for us to debug in case of an error.</p>
                <Button className="mb-2" variant={'outline'} onClick={captureScreen}><Camera />&nbsp;Take a screenshot</Button> */}

                {/* Display error message if any */}
                {error && <FormErrorMessage>{error}</FormErrorMessage>}

                {/* Video element to display the screen capture */}
                {/* <video ref={videoRef} autoPlay style={{ width: '100%', marginTop: '20px' }} controls></video> */}

                {/* Display Screenshot */}
                {/* {screenshotData && (
                  <div className="mt-2">
                    <h4 className="text-sm mb-2">Captured Screenshot:</h4>
                    <img src={screenshotData} alt="Captured Screenshot" className="w-full border" />
                  </div>
                )} */}

                {/* Submit Button */}
                <div className="flex justify-end">
                  <Button onClick={handleSubmit}  className="">
                    Send
                  </Button>
                </div>
              </div>
            )
          }
        </div>
      )}
    </div>
  );
};

