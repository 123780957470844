
import { PlymouthConfetti } from "@components/confetti";
import { Link } from "react-router-dom";

export const OnboardingCompleted: React.FC = () => {
  const title = `Wooohoo, you're done!`
  const subtitle = `Pat yourself in the back for making it this far. We will take it from here.`
  return (
    <>
      <div className="w-screen h-screen flex items-center justify-center">
        <div>
          <h1 className="text-3xl font-semibold mb-3">{title}</h1>
          <h2 className="text-xl mb-3">{subtitle}</h2>
          <p className="font-semibold mb-3">We'll be in touch soon to followup on your petition.</p>
          <p><Link className="text-accent" to="/">Go back to dashboard</Link></p>
        </div>
      </div>
      <PlymouthConfetti />
    </>
  )
}