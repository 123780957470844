import Spinner from "@components/spinner";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const Logout = () => {
  const navigate = useNavigate();

  useEffect(() => {
    navigate("/");
  }, [navigate]);

  return (
    <div className="flex flex-col items-center justify-center h-screen">
      <Spinner text="Loading user details..." />
    </div>
  );
};

export default Logout;
