import { useAllImmigrationDocsByUserQuery } from '@codegen/index';
import { CompanyDocumentType, ImmigrationDocumentType } from '@codegen/schema';
import { Button } from '@components/button';
import Spinner from '@components/spinner';
import Uploader from '@components/uploader/uploader';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { OnboardingContainer } from '@pages/onboarding/shared/onboarding-container';
import { OnboardingPageType } from '@pages/onboarding/types';
import { api } from '@utils/api';
import { useLogError } from '@utils/error';
import { useJwt } from '@utils/hooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

export const eadCardFormSchema = z.object({
  eadCardStamps: z.string().min(1, 'Please upload your Ead card')
});

export type EADCardFormType = z.infer<typeof eadCardFormSchema>;
export const EadCardForm: React.FC<OnboardingPageType> = ({ onSubmit, onBack, onSkip, contact, exhibit }) => {
  const [eadCardUploaded, setEadCardUploaded] = React.useState(false);
  const logError = useLogError()
  const { required } = exhibit
  const { data: immigrationDocsData, loading } = useAllImmigrationDocsByUserQuery({
    variables: {
      ownerId: contact.id
    }
  })


  const token = useJwt();
  const data = {}
  const existingAEDCards = React.useMemo(() => {
    if (data == null || immigrationDocsData?.allImmigrationDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of immigrationDocsData.allImmigrationDocuments.nodes) {
      if (doc?.fileByFileId?.id == null) {
        continue;
      }

      if (doc.type === ImmigrationDocumentType.EadCard) {
        res.push({
          id: doc.fileByFileId.id ?? "",
          name: doc.fileByFileId.name,
        });
      }
    }
    return res;
  }, [data]);

  const handleSubmit = () => {
    onSubmit(existingAEDCards)
  }

  const oneadCardUpload = React.useCallback(
    async (file: File) => {
      try {
        const data = await api.files.uploadImmigrationDoc(
          file,
          token,
          contact.id,
          ImmigrationDocumentType.EadCard
        );
        if (data.success) setEadCardUploaded(true);
        const { id } = data

        return {
          success: data.success,
          link: "",
          fileId: id
        };
      } catch (exception) {
        logError(exception, {
          contactId: contact.id,
          fileType: ImmigrationDocumentType.EadCard
        })
        return {
          success: false,
          link: ""
        }
      }
    },
    [contact.id, token]
  );

  return (
      <OnboardingContainer
        title={"Identification information"}
        subtitle={"If you have ever worked in the U.S. using an “EAD” card, please upload these here."}
        cardTitle={"Upload EAD card"}
        progress={0}
        onSubmit={handleSubmit}
      >
        <FieldsContainer status={''}>
          <div>
            <Button variant="link" className="pl-0">
              EAD Cards
            </Button>
            {existingAEDCards == null && <Spinner />}
            {existingAEDCards != null && (
              <Uploader
                multiple
                onFileUpload={oneadCardUpload}
                defaultFiles={existingAEDCards.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
          </div>
        </FieldsContainer>
        <CTAContainer onBack={onBack} onSkip={onSkip}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            type="submit"
            disabled={required && !eadCardUploaded}
          >
            Next
          </Button>
        </CTAContainer>
      </OnboardingContainer>
  );
}

export default EadCardForm;
