import { ExhibitOwnerType } from "@codegen/schema";
import { CreatorModule } from "@components/creator/types";

export const archetypes = [
  "technology-founder",
  "software-engineer",
  "critical-employee",
  "researcher-scientist",
] as const;

export type Archetype = (typeof archetypes)[number];

export const moduleTemplatesByArchetype: Record<Archetype, CreatorModule[]> = {
  "technology-founder": [
    {
      type: "critical-role",
      name: "Critical Role",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "offer-letter",
          name: "Offer Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "ev-letter",
          name: "EV Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "org-chart",
          name: "Organization Chart",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "calendar",
          name: "Calendar",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "internal-docs",
          name: "Internal Documentation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pitch-deck",
          name: "Pitch Deck",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "original-code",
          name: "Original Code",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "code-contributor-page",
          name: "Code Contributor Page",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "code-project-ownership",
          name: "Code Project Ownership",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "promotion-evidence",
          name: "Promotion Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-raise-evidence",
          name: "Pay Raise Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "customer-contract",
          name: "Customer Contract",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "distinguished-reputation",
          name: "Distinguished Reputation",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "original-contribution",
      name: "Original Contribution",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "original-contributions-summary",
          name: "Original Contributions Summary",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "product-roadmap",
          name: "Product Roadmap",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "technical-doc",
          name: "Technical Document",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "customer-contract",
          name: "Customer Contract",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "user-count-or-revenue",
          name: "User Count or Revenue",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "press-article",
          name: "Press Article",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "vc-evidence",
          name: "VC Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-citation",
          name: "Evidence of Citation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-commercial-adoption",
          name: "Evidence of Commercial Adoption",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-significant-usage",
          name: "Evidence of Significant Usage",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-industry-awards",
          name: "Evidence of Industry Awards",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
      ],
    },
    {
      type: "press",
      name: "Press",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "press-article",
          name: "Press Article",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "press-summary",
          name: "Press Summary",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "membership",
      name: "Membership",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-selectivity",
          name: "Evidence of Selectivity",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "evidence-of-selection-criteria",
          name: "Evidence of Selection Criteria",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "membership-documentation",
          name: "Membership Documentation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "judges",
          name: "Judges",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "proof-of-membership-letter-or-email",
          name: "Proof of Membership Letter or Email",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "proof-of-membership-profile-page",
          name: "Proof of Membership Profile Page",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-recognition",
          name: "Evidence of Recognition",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "high-remuneration",
      name: "High Remuneration",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "ev-letter",
          name: "EV Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "offer-letter",
          name: "Offer Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "tax-return",
          name: "Tax Return",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "equity-statement",
          name: "Equity Statement",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-stubs",
          name: "Pay Stubs",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-bonus",
          name: "Pay Bonus",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "promotion-evidence",
          name: "Promotion Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-raise-evidence",
          name: "Pay Raise Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "compensation-benchmark",
          name: "Compensation Benchmark",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "awards",
      name: "Award",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "award-evidence",
          name: "Award Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "vc-evidence",
          name: "VC Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "vc-profiles",
          name: "VC Profiles",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "cap-table",
          name: "Cap Table",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "profile-of-judges",
          name: "Profile of Judges",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-recognition",
          name: "Evidence of Recognition",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-selectivity",
          name: "Evidence of Selectivity",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "evidence-of-selection-criteria",
          name: "Evidence of Selection Criteria",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
  ],

  "software-engineer": [
    {
      type: "critical-role",
      name: "Critical Role",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "offer-letter",
          name: "Offer Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "ev-letter",
          name: "EV Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "org-chart",
          name: "Org Chart",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "calendar",
          name: "Calendar",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "internal-docs",
          name: "Internal Documentation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "code-contributor-page",
          name: "Code Contributor Page",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "code-project-ownership",
          name: "Code Project Ownership",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-criticality",
          name: "Evidence of Criticality",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "promotion-evidence",
          name: "Promotion Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-raise-evidence",
          name: "Pay Raise Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "customer-contract",
          name: "Customer Contract",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "distinguished-reputation",
          name: "Distinguished Reputation",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "original-contribution",
      name: "Original Contributions",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "original-contributions-summary",
          name: "Original Contributions Summary",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "code-contributor-page",
          name: "Code Contributor Page",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "code-project-ownership",
          name: "Code Project Ownership",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "customer-contract",
          name: "Customer Contract",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "product-roadmap",
          name: "Product Roadmap",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "technical-doc",
          name: "Technical Document",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "original-code",
          name: "Original Code",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "user-count-or-revenue",
          name: "User Count or Revenue",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-citation",
          name: "Evidence of Citation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-commercial-adoption",
          name: "Evidence of Commercial Adoption",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-significant-usage",
          name: "Evidence of Significant Usage",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-industry-awards",
          name: "Evidence of Industry Awards",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
      ],
    },
    {
      type: "high-remuneration",
      name: "High Remuneration",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "ev-letter",
          name: "EV Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "offer-letter",
          name: "Offer Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "tax-return",
          name: "Tax Return",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "equity-statement",
          name: "Equity Statement",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-stubs",
          name: "Pay Stubs",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-bonus",
          name: "Pay Bonus",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "promotion-evidence",
          name: "Promotion Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-raise-evidence",
          name: "Pay Raise Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "compensation-benchmark",
          name: "Compensation Benchmark",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "judging",
      name: "Judging",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "judging-invite-or-confirmation",
          name: "Judging Invite or Confirmation",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "judges",
          name: "Judges",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "judging-documentation",
          name: "Judging Documentation",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "screenshot-of-event-or-prizes",
          name: "Screenshot of Event or Prizes",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "link-to-event",
          name: "Link to a web event page",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
      ],
    },
  ],

  "critical-employee": [
    {
      type: "critical-role",
      name: "Critical Role",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "ev-letter",
          name: "EV Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "offer-letter",
          name: "Offer Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "calendar",
          name: "Calendar",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "internal-docs",
          name: "Internal Documentation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "org-chart",
          name: "Organization Chart",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "promotion-evidence",
          name: "Promotion Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-raise-evidence",
          name: "Pay Raise Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "customer-contract",
          name: "Customer Contract",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "distinguished-reputation",
          name: "Distinguished Reputation",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "high-remuneration",
      name: "High Remuneration",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "ev-letter",
          name: "EV Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "offer-letter",
          name: "Offer Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "tax-return",
          name: "Tax Return",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "equity-statement",
          name: "Equity Statement",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-stubs",
          name: "Pay Stubs",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-bonus",
          name: "Pay Bonus",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "promotion-evidence",
          name: "Promotion Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-raise-evidence",
          name: "Pay Raise Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "compensation-benchmark",
          name: "Compensation Benchmark",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "judging",
      name: "Judging",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "judging-invite-or-confirmation",
          name: "Judging Invite or Confirmation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "judges",
          name: "Judges",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "judging-documentation",
          name: "Judging Documentation",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "screenshot-of-event-or-prizes",
          name: "Screenshot of Event or Prizes",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "link-to-event",
          name: "Link to a web event page",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
      ],
    },
    {
      type: "original-contribution",
      name: "Original Contributions",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "original-contributions-summary",
          name: "Original Contributions Summary",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "product-roadmap",
          name: "Product Roadmap",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "technical-doc",
          name: "Technical Document",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "customer-contract",
          name: "Customer Contract",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "user-count-or-revenue",
          name: "User Count or Revenue",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-citation",
          name: "Evidence of Citation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-commercial-adoption",
          name: "Evidence of Commercial Adoption",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-significant-usage",
          name: "Evidence of Significant Usage",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-industry-awards",
          name: "Evidence of Industry Awards",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
      ],
    },
  ],
  "researcher-scientist": [
    {
      type: "authorship",
      name: "Authorship",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "google-scholar-page",
          name: "Google Scholar Page",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "citation-page",
          name: "Citation Page",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "journal-article",
          name: "Journal Article",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "conference-presentation",
          name: "Conference Presentation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "publication-prominence",
          name: "Publication Prominence",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "critical-role",
      name: "Critical Role",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "ev-letter",
          name: "EV Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "offer-letter",
          name: "Offer Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "calendar",
          name: "Calendar",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "internal-docs",
          name: "Internal Docs",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "org-chart",
          name: "Organization Chart",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-criticality",
          name: "Evidence of Criticality",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "promotion-evidence",
          name: "Promotion Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-raise-evidence",
          name: "Pay Raise Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "customer-contract",
          name: "Customer Contract",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "distinguished-reputation",
          name: "Distinguished Reputation",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "high-remuneration",
      name: "High Remuneration",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "ev-letter",
          name: "EV Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "offer-letter",
          name: "Offer Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "tax-return",
          name: "Tax Return",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "equity-statement",
          name: "Equity Statement",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-stubs",
          name: "Pay Stubs",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "promotion-evidence",
          name: "Promotion Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "pay-raise-evidence",
          name: "Pay Raise Evidence",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "compensation-benchmark",
          name: "Compensation Benchmark",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
      ],
    },
    {
      type: "original-contribution",
      name: "Original Contributions",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "original-contributions-summary",
          name: "Original Contributions Summary",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "patent",
          name: "Patent",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "original-code",
          name: "Original Code",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "product-roadmap",
          name: "Product Roadmap",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "technical-doc",
          name: "Technical Document",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "customer-contract",
          name: "Customer Contract",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "user-count-or-revenue",
          name: "User Count or Revenue",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-citation",
          name: "Evidence of Citation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-commercial-adoption",
          name: "Evidence of Commercial Adoption",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-significant-usage",
          name: "Evidence of Significant Usage",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "evidence-of-industry-awards",
          name: "Evidence of Industry Awards",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
      ],
    },
    {
      type: "judging",
      name: "Judging",
      data: {},
      visible: true,
      exhibits: [
        {
          type: "expert-letter",
          name: "Expert Letter",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "judging-invite-or-confirmation",
          name: "Judging Invite or Confirmation",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "judging-documentation",
          name: "Judging Documentation",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "publication-prominence",
          name: "Publication Prominence",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "judges",
          name: "Judges",
          description: "",
          ownedBy: ExhibitOwnerType.Plymouth,
          visible: false,
        },
        {
          type: "screenshot-of-event-or-prizes",
          name: "Screenshot of Event or Prizes",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
        {
          type: "link-to-event",
          name: "Link to a Web Event Page",
          description: "",
          ownedBy: ExhibitOwnerType.Beneficiary,
          visible: true,
        },
      ],
    },
  ],
};
