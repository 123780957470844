import { AppState, Auth0Provider } from "@auth0/auth0-react";
import React, { ReactNode } from "react";
import { useNavigate } from "react-router-dom";

export const Auth0ProviderWithNavigate = ({
  children,
}: {
  children: ReactNode;
}) => {
  const navigate = useNavigate();

  const domain: string | null = import.meta.env.VITE_AUTH0_DOMAIN;
  const clientId: string | null = import.meta.env.VITE_AUTH0_CLIENT_ID;
  const redirectUri: string = window.location.origin;

  const onRedirectCallback = (appState?: AppState) => {
    const path = appState?.returnTo ?? "/";
    navigate(path);
  };

  if (!(domain != null && clientId != null)) {
    console.error("Missing Auth0 env variables");
    return null;
  }

  return (
    <Auth0Provider
      domain={domain}
      clientId={clientId}
      onRedirectCallback={onRedirectCallback}
      useCookiesForTransactions={true}
      authorizationParams={{
        redirect_uri: redirectUri,
        audience: `https://${import.meta.env.VITE_AUTH0_DOMAIN}/api/v2/`,
      }}
    >
      {children}
    </Auth0Provider>
  );
};
