import BuilderNavigator from "@components/adminPageNavigator";
import { PetitionSelectAutoComplete } from "@components/autocomplete";
import { Button } from "@components/button";
import { Plus } from "@phosphor-icons/react";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

const PetitionBuilderHome = () => {
  const nav = useNavigate();

  const onSelect = useCallback(
    (x: string) => nav(`/admin/petition-builder/${x}`),
    [nav]
  );

  const onSelectCreate = useCallback(
    () => nav(`/admin/petition-builder/new`),
    [nav]
  );

  return (
    <div className="h-screen w-screen flex flex-col overflow-hidden bg-gray-50 p-4 gap-y-4">
      <BuilderNavigator />
      <div className="flex flex-col w-full h-1/2 gap-x-2 items-center justify-center">
        <div className="flex flex-col gap-y-3">
          <h1 className="text-3xl font-semibold">Petition Builder</h1>
          <div className="flex flex-col gap-y-2">
            <PetitionSelectAutoComplete onSelect={onSelect} value="" />
            <Button
              variant="outline"
              className="h-12 flex flex-row gap-x-1"
              onClick={onSelectCreate}
            >
              <h3 className="text-lg">Create a new Petition</h3>
              <Plus size={18} />
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PetitionBuilderHome;
