import { useMemo } from "react";
import { Combobox } from "./combobox";
import { visaClassMap } from "@utils/parsers";

const VisaClassSelector = (props: {
  selectedType?: string;
  onSelect?: (type: string) => void;
  className?: string;
  disabled?: boolean;
}) => {
  const { selectedType, onSelect, disabled, className } = props;

  const visaClassValues = useMemo(() => {
    const res = [];

    for (const [k, v] of Object.entries(visaClassMap)) {
      res.push({
        value: k,
        label: v,
      });
    }

    return res;
  }, []);

  return (
    <Combobox
      options={visaClassValues}
      defaultValue={selectedType ?? ""}
      onSelect={(e) => onSelect?.(e)}
      placeholder="Search visa type"
      className={className}
      disabled={disabled}
    />
  );
};

export default VisaClassSelector;
