import { Extension } from "@tiptap/core";

declare module "@tiptap/core" {
  interface Commands<ReturnType> {
    customAiWriter: {
      fetchAndInsertText: () => ReturnType;
    };
  }
}

const CustomAIWriter = Extension.create({
  name: "customAiWriter",

  addOptions() {
    return {
      id: null,
      type: null,
    };
  },

  addCommands() {
    return {
      fetchAndInsertText: () => async () => {
        const opts = (
          this.editor.extensionManager.extensions.find(
            (ext) => ext.name === "customAiWriter"
          ) ?? { options: {} }
        ).options;

        const { id, type } = opts;

        if (id == null || type == null) {
          // If id or type is not provided, do nothing
          return false;
        }

        const requestBody = new URLSearchParams(
          type === "exhibit"
            ? {
                exhibit_id: id,
              }
            : {
                module_id: id,
              }
        );

        // disable editor
        this.editor.setEditable(false);

        const res = await fetch(
          `https://research-agent-docker-c7q1.onrender.com/${type}/description`,
          {
            method: "POST",
            headers: {
              "Content-Type": "application/x-www-form-urlencoded",
            },
            body: requestBody,
          }
        );

        if (!res.ok) {
          // If fetch fails, do nothing
          console.error(`failed to fetch ${type} description with id ${id}`);
          this.editor.setEditable(true);
          return false;
        }

        const text = await res.text();

        if (text.trim().length === 0) {
          console.log("no text returned from description generator");
          this.editor.setEditable(true);
          return false;
        }

        // select all text
        this.editor.chain().focus().selectAll().run();
        // delete all text
        this.editor.chain().focus().deleteSelection().run();
        // insert text
        this.editor.chain().focus().insertContent(text).run();
        this.editor.setEditable(true);
      },
    };
  },
});

export { CustomAIWriter };
export default CustomAIWriter;
