import { FormStatus } from "@utils/types";
import { createContext } from "react";

export type LetterTypes =
  | "critical-role"
  | "original-contributions"
  | "cr-oc-letter"
  | "ao-letter"
  | "membership"
  | "award/vc"
  | "tn-support-letter"
  | "ev-letter";

export type DraftContextType = {
  baseInputs: {
    letterType: LetterTypes;
    visaClass: string;
    howTheyMet: string;
    fieldOfEndeavor: string;
    signatoryEmployer: {
      id: string;
      name: string;
    };
    beneficiary: {
      id: string;
      name: string;
      bio: string;
      title: string;
    };
    signatory: {
      id: string;
      name: string;
      bio: string;
      title: string;
    };
    company: {
      id: string;
      name: string;
      bio: string;
    };
    archetype: string;
    additionalInfo: string;
  };
  ocLetterOptions?: {
    docs: FileList | null;
  };
  aoLetterOptions?: {
    criteria: string[];
  };
  membershipLetterOptions?: {
    entityId: string;
    description: string;
    selectivity: string;
  };
  awardVcLetterOptions?: {
    entityId: string;
    selectionCriteria: string;
    fundingAmount: string;
    fundingYear: string;
  };
  tnLetterOptions?: {
    resume: File | null;
    offerLetter: File | null;
    tnCategory: "Engineer" | string;
  };
  evLetterOptions?: {
    salary: string;
    equity: string;
    previousRole: boolean;
  }
};

const DraftContext = createContext<{
  data: DraftContextType;
  setData: React.Dispatch<React.SetStateAction<DraftContextType>>;
  status: FormStatus;
  setStatus: React.Dispatch<React.SetStateAction<FormStatus>>;
  generatedLetter: string;
  setGeneratedLetter: React.Dispatch<React.SetStateAction<string>>;
}>({
  data: {
    baseInputs: {
      letterType: "original-contributions",
      visaClass: "",
      howTheyMet: "",
      fieldOfEndeavor: "",
      signatoryEmployer: {
        id: "",
        name: "",
      },
      beneficiary: {
        id: "",
        name: "",
        bio: "",
        title: "",
      },
      signatory: {
        id: "",
        name: "",
        bio: "",
        title: "",
      },
      company: {
        id: "",
        name: "",
        bio: "",
      },
      archetype: "",
      additionalInfo: "",
    },
  },
  setData: () => {
    return;
  },
  status: "idle",
  setStatus: () => {
    return;
  },
  generatedLetter: "",
  setGeneratedLetter: () => {
    return;
  },
});

export default DraftContext;
