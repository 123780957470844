import { Progress } from "@components/progress";
import { FieldValues, useFormContext } from "react-hook-form";
import { ErrorBoundary } from "@sentry/react";
import React from "react";
import { useStatsigClient } from "@statsig/react-bindings";
import { X } from "@phosphor-icons/react";
import { Link } from "react-router-dom";
import { WorkflowExhibit } from "@codegen/enums";
import * as changeCase from 'change-case'
import { FormErrorMessage } from "./error";
import { Button } from "@components/button";
import { useSupportContext } from "@providers/support";

type OnboardingContainerProps<TFields extends FieldValues> = {
  children: React.ReactNode;
  progress: number;
  title: React.ReactNode;
  subtitle?: React.ReactNode;
  cardTitle?: React.ReactNode;
  footnote?: React.ReactNode;
  onSubmit?: any;
  name?: string;
  exhibit?: WorkflowExhibit;
}

export const OnboardingContainer = <TFields extends FieldValues>(props: OnboardingContainerProps<TFields>) => {
  const { children, title, subtitle, cardTitle, progress, name, onSubmit, footnote, exhibit } = props;
  const { client } = useStatsigClient();
  const { handleSubmit, formState } = useFormContext() ?? {};
  const { openPanel } = useSupportContext()
  React.useEffect(() => {
    client.logEvent('onboarding_view', name ?? `${changeCase.capitalCase(exhibit?.type ?? '')}`, {
      onboardingExhibitId: exhibit?.id ?? '',
      exhibitId: exhibit?.exhibitId?.toString() ?? ''
    })
  }, [])

  const submit = handleSubmit ? handleSubmit(onSubmit) : (event: React.SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault()
    const formData = new FormData(event.target as HTMLFormElement)
    const values = formData.entries()
    onSubmit(Array.from(values))
  }  

  return (
    <ErrorBoundary>
      <div className="w-screen min-h-screen bg-[url(/bg-journey.svg)]">
        <div className="h-screen w-full">
          <div className="w-full h-full flex flex-col relative">
            <Progress value={progress} className="w-full absolute top-0 left-0 right-0" />
            <Link to="/onboarding" title="Exit" className="absolute top-0 right-0 p-5 text-gray-400 "><X size={30} className="hover:bg-gray-100" /></Link>
            <div className="flex flex-col m-auto w-full md:w-[671px] min-h-full justify-center">
              <form
                className="max-h-[calc(100%-166px)] overflow-y-scroll overflow-x-visible px-3 md:px-0"
                onSubmit={submit}
              >
                <div className="flex flex-col gap-4">
                  <div className="space-y-4 mb-4">
                    <h1 className="text-3xl font-semibold">{title}</h1>
                    {subtitle && (<h2 className="text-primary">{subtitle}</h2>)}
                  </div>
                  {cardTitle && (<h3 className="font-semibold mb-2">{cardTitle}</h3>)}
                </div>
                {children}
                {formState?.errors?.root?.serverError && (
                  <div>
                    <FormErrorMessage>There was an error saving your inputs. Please verify they are correct.</FormErrorMessage>
                    <p className="text-sm">If you need more help, please&nbsp;<Button onClick={openPanel} type="button" variant="link" className="px-0 text-blue-500">send us a message.</Button></p>
                  </div>
                )}
                {footnote}
              </form>
            </div>

          </div>
        </div>
      </div>
    </ErrorBoundary>
  );
};

