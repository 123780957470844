import * as React from "react";
import { CaretSortIcon, CheckIcon } from "@radix-ui/react-icons";

import { cn } from "@utils/cn";
import { Button } from "@components/button";
import {
  Command,
  CommandEmpty,
  CommandGroup,
  CommandInput,
  CommandItem,
} from "@components/command";
import { Popover, PopoverContent, PopoverTrigger } from "@components/popover";
import { useEffect } from "react";

export function Combobox(props: {
  options: { value: string; label: string }[];
  placeholder: string;
  onSelect: (value: string) => void;
  defaultValue?: string;
  showMax?: number;
  className?: string;
  disabled?: boolean;
}) {
  const [open, setOpen] = React.useState(false);
  const [value, setValue] = React.useState("");

  const doSelect = React.useCallback(
    (value: string) => {
      setValue(value);
      setOpen(false);
      props.onSelect(value);
    },
    [props]
  );

  useEffect(() => {
    setValue(props.defaultValue ?? "");
  }, [props.defaultValue]);

  const memoValue = React.useMemo(
    () =>
      value != null || value !== ""
        ? props.options.find((option) => option.value === value)?.label
        : props.placeholder,
    [value, props.options, props.placeholder]
  );

  return (
    <Popover open={open} onOpenChange={setOpen}>
      <PopoverTrigger asChild>
        <Button
          variant="outline"
          role="combobox"
          aria-expanded={open}
          className={cn("w-[220px] justify-between", props.className)}
          disabled={props.disabled ?? false}
        >
          <p className="truncate">{memoValue}</p>
          <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
        </Button>
      </PopoverTrigger>
      <PopoverContent className={"p-0 min-w-full " + props.className}>
        <Command className="w-full">
          <CommandInput placeholder={props.placeholder} className="h-9" />
          <CommandEmpty>No options found.</CommandEmpty>
          <CommandGroup className="h-20 overflow-auto">
            {props.options.map((option) => (
              <CommandItem
                key={option.value}
                onSelect={(currentValue) => {
                  doSelect(
                    currentValue === value.toLowerCase() ? "" : option.value
                  );
                  setOpen(false);
                }}
              >
                {option.label}
                <CheckIcon
                  className={cn(
                    "ml-auto h-4 w-4",
                    value === option.value ? "opacity-100" : "opacity-0"
                  )}
                />
              </CommandItem>
            ))}
          </CommandGroup>
        </Command>
      </PopoverContent>
    </Popover>
  );
}
