
import Spinner from "@components/spinner";

export const FieldsContainer = (props: {
  children: React.ReactNode;
  status: string;
  form?: any;
}) => {
  const { children, status } = props;

  return (
    <div className="flex flex-col gap-3 overflow-x-visible">
      {children}
      <div className="pt-5 gap-x-2 flex flex-row items-center">
        {status === "error" && (
          <p className="text-sm text-red-500">
            There was an error submitting your information.
          </p>
        )}
        {status === "loading" && <Spinner />}
      </div>
    </div>
  );
};
