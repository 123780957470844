import { useAllUserDocumentsByTypeQuery } from '@codegen/index';
import { CompanyDocumentType, ImmigrationDocumentType, IndividualDocumentType } from '@codegen/schema';
import { Button } from '@components/button';
import Spinner from '@components/spinner';
import Uploader from '@components/uploader/uploader';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { OnboardingContainer } from '@pages/onboarding/shared/onboarding-container';
import { OnboardingPageType } from '@pages/onboarding/types';
import { api } from '@utils/api';
import { useLogError } from '@utils/error';
import { useJwt } from '@utils/hooks';
import * as React from 'react';
import { Controller, FormProvider, useFieldArray, useForm } from 'react-hook-form';
import { z } from 'zod';
import * as changeCase from 'change-case'
import { FormControl, FormLabel } from '@components/form';
import { Input } from '@components/input';
import { X } from '@phosphor-icons/react';
import { FileFootnote } from '@pages/onboarding/shared/footnotes';
import { zodResolver } from '@hookform/resolvers/zod';
import { ErrorMessage } from '@hookform/error-message';
import { FormErrorMessage } from '@pages/onboarding/shared/error';
import { CriticalRoleInternalDocuments, CriticalRolePatents, CriticalRoleScholarlyArticle } from '@pages/onboarding/workflows/descriptions';

export const fileFormSchema = z.union([z.object({
  links: z.string().url().array().min(1, 'Please provide a valid URL'),
}), z.object({
  files: z.string().array().min(1, 'Please upload at least one file'),
})])

export type FileFormType = z.infer<typeof fileFormSchema>;
export const FileWithLinksForm: React.FC<OnboardingPageType & { fileType: IndividualDocumentType, title: string, subtitle: React.ReactNode, cardTitle: React.ReactNode }> = ({
  contact, onBack, onSubmit, onSkip, fileType, title, subtitle, cardTitle, footnote, exhibit
}) => {
  const { required, data } = exhibit
  const { links }: { links?: string[], files?: string[]} = data as FileFormType ?? {
    links: [''],
    files: []
  }
  const [fileUploaded, setFileUploaded] = React.useState(false);
  const token = useJwt()
  const logError = useLogError()

  const { data: userData, loading } = useAllUserDocumentsByTypeQuery({
    variables: {
      userId: contact.id,
      documentType: fileType
    }
  })
  const existingFiles = userData?.allUserDocuments?.nodes?.map(node => node?.fileByFileId).filter(n => !!n) ?? []
  const methods = useForm<FileFormType>({
    defaultValues: {
      links: links,
      files: existingFiles?.map(f => f.id)
    },
    resolver: zodResolver(fileFormSchema),
  });
  const { getValues, setValue } = methods
  const handleSubmit = (data?: FileFormType) => {
    onSubmit(data)
  }

  const onFileUpload = React.useCallback(
    async (file: File) => {
      try {
        const data = await api.files.uploadIndividualDoc(
          file,
          token,
          contact.id,
          fileType
        );
        if (data.success) setFileUploaded(true);
        const { id } = data
        const files = getValues('files')
        setValue('files', [...files, id])

        return {
          success: data.success,
          link: "",
          fileId: id
        };
      } catch (exception) {
        logError(exception)
        return {
          success: false,
          link: ""
        }
      }
    },
    [contact.id, token, setValue, getValues]
  );

  const { formState, control } = methods
  const { fields, append, remove } = useFieldArray({
    control,
    // @ts-ignore TODO: Not sure how to fix
    name: "links",
  });

  React.useEffect(() => {
    if (fields.length < 1) {
      append('')
    }
  }, [fields, append])

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={title}
        subtitle={subtitle}
        cardTitle={cardTitle}
        progress={0}
        onSubmit={handleSubmit}
        footnote={footnote}
      >
        <FieldsContainer status={''}>
          <div className="">
            <div>
              <Button variant="link" className="pl-0">
                {changeCase.sentenceCase(fileType)}&nbsp;links
              </Button>
            </div>
            <div className="flex flex-col gap-3">
              {
                fields.map((field, index) => (
                  <div key={field.id} className="rounded-xl border border-dashed border-black/50 bg-onboarding p-3 relative">
                    <div>
                      <FormLabel className="text-primary">Link {index + 1}</FormLabel>
                      <Controller
                        name={`links.${index}`}
                        control={control}
                        render={({ field }) => (
                          <Input {...field} className="w-full" type="text" placeholder="https://www.google.com" />
                        )}
                      />
                      <ErrorMessage
                        errors={formState.errors}
                        name={`links.${index}`}
                        render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
                      />
                    </div>
                    <button onClick={() => remove(index)} className="absolute top-0 right-0 p-2"><X /></button>
                  </div>
                ))
              }
            </div>
            <Button type="button" variant={'link'} className="self-start text-accent px-0" onClick={() => append('')}>Add more link</Button>
          </div>
          <div>
            <Button variant="link" className="pl-0">
              {changeCase.sentenceCase(fileType)} files
            </Button>
            {existingFiles == null && <Spinner />}
            {existingFiles != null && (
              <Uploader
                multiple
                onFileUpload={onFileUpload}
                defaultFiles={existingFiles.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
            <ErrorMessage
              errors={formState.errors}
              name={`files`}
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </div>
        </FieldsContainer>
        <CTAContainer onBack={onBack} onSkip={required ? undefined : onSkip}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            type="submit"
          >
            Next
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
}

export const PatentForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <FileWithLinksForm {...props}
      subtitle={
        CriticalRolePatents
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.Patent}
      footnote={FileFootnote}
    />
  )
}

export const InternalDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <FileWithLinksForm {...props}
      subtitle={
        CriticalRoleInternalDocuments
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.InternalDocs}
    />
  )
}

export const MembershipDocuments: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <FileWithLinksForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}`}
      fileType={IndividualDocumentType.MembershipDocumentation}
    />
  )
}

export const JudgingDocumentForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { data, name, description } = exhibit
  const { name: moduleName } = module
  return (
    <FileWithLinksForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}`}
      fileType={IndividualDocumentType.JudgingDocumentation}
      footnote={FileFootnote}
    />
  )
}

export const PressArticleForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <FileWithLinksForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}`}
      fileType={IndividualDocumentType.PressArticle}
    />
  )
}


export const OriginalContributionEvidenceForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <FileWithLinksForm {...props}
      subtitle={
        name
      }
      cardTitle={''}
      title={`${moduleName}`}
      fileType={IndividualDocumentType.PublicationProminence}
      footnote={FileFootnote}
    />
  )
}

export const JournalArticleWithLinksForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <FileWithLinksForm {...props}
      subtitle={
        CriticalRoleScholarlyArticle
      }
      cardTitle={''}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.JournalArticle}
      footnote={FileFootnote}
    />
  )
}

export const EvidenceOfCitationForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <FileWithLinksForm {...props}
      subtitle={
        name
      }
      cardTitle={'Evidence showing your work has been widely cited or referenced by others in your industry'}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.EvidenceOfCitation}
      footnote={FileFootnote}
    />
  )
}

export const PublicationProminenceForm: React.FC<OnboardingPageType> = (props) => {
  const { exhibit, module } = props
  const { name } = exhibit
  const { name: moduleName } = module
  return (
    <FileWithLinksForm {...props}
      subtitle={
        name
      }
      cardTitle={'Any other major publications where your work has appeared'}
      title={`${moduleName}${name ? ` - ${name}` : ''}`}
      fileType={IndividualDocumentType.PublicationProminence}
      footnote={FileFootnote}
    />
  )
}
