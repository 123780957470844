import { useMemo } from "react";
import { Combobox } from "./combobox";
import React from "react";
import { Archetype, archetypes } from "@utils/archetypes";

const ArchetypeSelector = (props: {
  selectedType?: string;
  onSelect?: (type: Archetype) => void;
  className?: string;
}) => {
  const { selectedType, onSelect } = props;

  const opts = useMemo(() => {
    const opts = [];

    for (const type of archetypes) {
      if (type == null) continue;
      opts.push({
        value: type,
        label: type
          .split("-")
          .map((s) => s[0].toUpperCase() + s.slice(1))
          .join(" "),
      });
    }
    return opts;
  }, []);

  return (
    <Combobox
      options={opts}
      defaultValue={(selectedType as string) ?? ""}
      onSelect={(e) => onSelect?.(e as Archetype)}
      placeholder="Archetype"
      className={props.className}
    />
  );
};

const ArchetypeSelectorMemo = React.memo(ArchetypeSelector);
export default ArchetypeSelectorMemo;
