import { useEntitiesByTypeQuery } from "@codegen/index";
import { EntityTypes } from "@codegen/schema";
import { useEffect, useMemo, useRef, useState } from "react";
import { Popover, PopoverContent, PopoverTrigger } from "./popover";
import { Command } from "cmdk";
import Spinner from "./spinner";
import { Button } from "./button";
import { CaretSortIcon } from "@radix-ui/react-icons";
import { cn } from "@utils/cn";

const EntityObjectSelector = (props: {
  type: EntityTypes;
  onSelect: (entity: { id: string; name: string; type: string }) => void;
  selectedId: string;
  refreshed?: boolean;
  resetRefreshed?: () => void
}) => {
  const [value, setValue] = useState({
    id: props.selectedId,
    name: "",
    type: props.type,
  });

  const { data, loading, refetch } = useEntitiesByTypeQuery({
    variables: {
      type: props.type,
    },
  });

  const inputRef = useRef<HTMLInputElement | null>(null);
  const listRef = useRef(null);

  const [open, setOpen] = useState(false);
  const { refreshed, resetRefreshed } = props
  const options = useMemo(() => {
    if (data == null) return [];
    if (data.allAdditionalEntities?.nodes == null) return [];

    const res = [];
    const entities = props.entities ?? data.allAdditionalEntities.nodes
    for (const x of entities) {
      if (x == null) continue;

      const name =
        x.type === EntityTypes.Company
          ? x.companyByCompanyId?.dbaName
          : x.type === EntityTypes.User
          ? x.userByUserId?.fullName
          : x.name;

      res.push({
        label: {
          name: name ?? '',
          type: x.type,
          id: x.id as string,
        },
        value: x.id,
      });
    }
    return res;
  }, [data, props.type]);

  useEffect(() => {
    const fetch = async () => {
      await refetch()
      resetRefreshed?.()
    }
    if (refreshed) {
      fetch() 
    }
  }, [refreshed, resetRefreshed])

  useEffect(() => {
    if (props.selectedId == null) return;
    const v = options.find((o) => o.value === props.selectedId);
    if (v == null) return;
    setValue({
      id: v.label.id,
      name: v.label.name,
      type: v.label.type,
    });
  }, [options, props.selectedId, props.type]);

  return (
    <>
      {loading && <Spinner />}
      {data && options.length > 0 && (
        <Popover open={open} onOpenChange={setOpen}>
          <PopoverTrigger asChild>
            <Button
              variant="outline"
              role="combobox"
              aria-expanded={open}
              className={cn("w-[220px] justify-between bg-white")}
            >
              <p className="truncate">{value.name}</p>
              <CaretSortIcon className="ml-2 h-4 w-4 shrink-0 opacity-50" />
            </Button>
          </PopoverTrigger>
          <PopoverContent className="flex flex-col w-[220px] p-2 gap-y-3 overflow-scroll max-h-[200px]">
            <Command
              className="w-full flex flex-col gap-y-1"
              filter={(v, search) => {
                const label = options.find((o) => o.value === v)?.label;
                if (label == null) return 0;

                if (label.name.toLowerCase().includes(search.toLowerCase())) {
                  return 1;
                }

                if (label.type.toLowerCase().includes(search.toLowerCase())) {
                  return 0.5;
                }

                if (v.toLowerCase().includes(search.toLowerCase())) {
                  return 0.5;
                }

                return 0;
              }}
            >
              <Command.Input
                ref={inputRef}
                autoFocus
                placeholder="Search for an entity..."
              />

              <Command.List ref={listRef}>
                <Command.Empty>No results found.</Command.Empty>
                {options.map((v, idx) => (
                  <Command.Item
                    key={idx}
                    value={v.value}
                    onSelect={(currentValue) => {
                      const v = options.find((o) => o.value === currentValue);
                      if (v == null) return;

                      props.onSelect({
                        id: v.label.id,
                        name: v.label.name,
                        type: v.label.type,
                      });
                      setOpen(false);
                    }}
                    className="hover:bg-gray-100 hover:cursor-pointer"
                  >
                    <div className="text-sm w-full flex flex-row items-center justify-between gap-x-2">
                      <div className="text-gray-400 font-semibold">
                        {v.label.name}
                      </div>
                      <div className="text-xs text-gray-400 font-semibold">
                        {v.label.type}
                      </div>
                    </div>
                  </Command.Item>
                ))}
              </Command.List>
            </Command>
          </PopoverContent>
        </Popover>
      )}
    </>
  );
};

export default EntityObjectSelector;
