import { Button } from "@components/button";
import { isUserAdminOverridden } from "@utils/admin";
import { cn } from "@utils/cn";
import { useCallback } from "react";
import { useNavigate } from "react-router-dom";

export type AdminPageOptions =
  | "dashboard"
  | "researchagent"
  | "lettergen"
  | "playground"
  | "petition-builder";
export const adminPages: {
  title: string;
  page: AdminPageOptions;
  url: string;
  onClick?: () => void;
}[] = [
  {
    title: "Dashboard",
    page: "dashboard",
    url: "/admin",
  },
  {
    title: "Petition Builder",
    page: "petition-builder",
    url: "/admin/petition-builder",
  },
  {
    title: "Generate bio",
    page: "lettergen",
    url: "/admin/lettergen",
  },
];
const AdminNavigation = (props: { selectedPage: AdminPageOptions }) => {
  const navigate = useNavigate();

  const doNavigatePage = useCallback(
    (page: string) => {
      navigate(page);
    },
    [navigate]
  );

  return (
    <div className="hidden flex-col md:flex">
      <div className="border-b w-screen">
        <div className="flex flex-row h-16 items-center px-4">
          <h2 className="text-3xl font-light tracking-tight pr-4">
            Plymouth Admin Portal
          </h2>
          <div className="flex gap-x-3">
            {adminPages.map((page) => (
              <a
                key={page.page}
                onClick={() => doNavigatePage(page.url)}
                className={cn(
                  "text-sm font-medium transition-colors cursor-pointer",
                  props.selectedPage === page.page
                    ? "text-primary"
                    : "text-muted-foreground hover:text-primary"
                )}
              >
                {page.title}
              </a>
            ))}
          </div>
          {isUserAdminOverridden() && (
            <Button
              onClick={() => {
                navigate("/");
              }}
              className="bg-primary text-primary-foreground ml-auto"
            >
              Home
            </Button>
          )}
        </div>
      </div>
    </div>
  );
};

export default AdminNavigation;
