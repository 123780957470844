import { AlertCircleIcon } from "lucide-react";
import { useCallback, useEffect, useState } from "react";
import { Button } from "@components/button";
import { Textarea } from "@components/textArea";
import Spinner from "@components/spinner";
import { useFilePicker } from "use-file-picker";
import {
  useCompanyByIdQuery,
  useEditCompanyBioMutation,
  useEditContactBioMutation,
  useGetUserByIdQuery,
} from "@codegen/index";
import UserSelector from "@components/userSelector";
import CompanySelector from "@components/companySelector";

const BioEditor = (props: {
  id?: string;
  name: string;
  bio: string | null;
  company?: boolean;
}) => {
  const { company, id, name, bio } = props;

  const [editContactBioMutation, { error, loading: savingContactBio }] =
    useEditContactBioMutation();

  const [
    editCompanyBioMutation,
    { error: companyError, loading: savingCompanyBio },
  ] = useEditCompanyBioMutation();

  const [generationStatus, setGenerationStatus] = useState<
    "idle" | "loading" | "loading-pdf" | "success" | "error" | "error-pdf"
  >("idle");

  const [currBio, setCurrBio] = useState<string | null>(bio);

  const lettergenApiUrl = import.meta.env.VITE_INTERNAL_LETTERGEN_API_URL;

  useEffect(() => {
    if (error != null) console.error(error);
  }, [error]);

  const doSave = useCallback(
    (newBio: string) => {
      if (id == null) return;

      if (!company) {
        editContactBioMutation({
          variables: {
            id: id,
            bio: newBio.trim() ?? "",
          },
        });
      } else {
        editCompanyBioMutation({
          variables: {
            id: id,
            bio: newBio.trim() ?? "",
          },
        });
      }
    },
    [id, company, editContactBioMutation, editCompanyBioMutation]
  );

  const [openFileSelector] = useFilePicker({
    readAs: "DataURL",
    accept: [".pdf"],
    multiple: false,

    onFilesRejected: (errors) => {
      console.error(errors);
      setGenerationStatus("error");
    },

    onFilesSuccessfulySelected: (files) =>
      doGenerateBioFromPDF(files.plainFiles[0]),
  });

  const doGenerateBioFromPDF = useCallback(
    async (file: File) => {
      if (generationStatus === "loading" || generationStatus == "loading-pdf")
        return;
      setGenerationStatus("loading-pdf");

      const adminToken: string = import.meta.env.VITE_INTERNAL_ADMIN_TOKEN;

      const formData = new FormData();
      formData.append("file", file);
      formData.append(company ? "company_name" : "user_name", name);

      const endpoint = company
        ? "generate-company-bio-from-pdf"
        : "generate-bio-from-pdf";

      try {
        const res = await fetch(`${lettergenApiUrl}/${endpoint}`, {
          method: "POST",
          headers: {
            Authorization: adminToken,
          },
          body: formData,
        });

        if (res.status !== 200) {
          setGenerationStatus("error-pdf");
          return;
        }

        const json = await res.json();

        if (json.generated_bio == null) {
          setGenerationStatus("error-pdf");
          return;
        }

        setCurrBio(json.generated_bio);
        setGenerationStatus("success");
      } catch (e) {
        console.error(e);
        setGenerationStatus("error-pdf");
      }
    },
    [generationStatus, name, company, lettergenApiUrl]
  );

  const doGenerateBio = useCallback(async () => {
    setGenerationStatus("loading");
    try {
      const res = await fetch(`${lettergenApiUrl}/generate-bio`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: import.meta.env.VITE_INTERNAL_ADMIN_TOKEN,
        },
        body: JSON.stringify({
          name,
        }),
      });

      if (res.status !== 200) {
        setGenerationStatus("error");
        return;
      }

      const json = await res.json();

      if (json.generated_bio == null) {
        setGenerationStatus("error");
        return;
      }

      setCurrBio(json.generated_bio);
      setGenerationStatus("success");
    } catch (e) {
      console.error(e);
      setGenerationStatus("error");
    }
  }, [lettergenApiUrl, name]);

  return (
    <div className="flex flex-col">
      <div className="flex flex-row gap-x-2 p-2">
        {!company && (
          <Button
            onClick={doGenerateBio}
            disabled={
              id == null ||
              generationStatus === "loading" ||
              generationStatus === "loading-pdf"
            }
          >
            {generationStatus === "loading" ? (
              <Spinner />
            ) : generationStatus === "error" ? (
              <AlertCircleIcon />
            ) : (
              "Generate Bio"
            )}
          </Button>
        )}

        <Button
          onClick={openFileSelector}
          disabled={id == null || generationStatus === "loading"}
        >
          {generationStatus === "loading-pdf" ? (
            <Spinner />
          ) : generationStatus === "error-pdf" ? (
            <AlertCircleIcon />
          ) : (
            "Generate Bio from PDF"
          )}
        </Button>

        <Button
          onClick={() => doSave(currBio ?? "")}
          disabled={
            currBio == bio ||
            generationStatus === "loading" ||
            generationStatus === "loading-pdf"
          }
        >
          Save
          {(savingContactBio || savingCompanyBio) && <Spinner />}
          {(error || companyError) && <AlertCircleIcon />}
        </Button>
      </div>

      <Textarea
        disabled={
          id == null ||
          generationStatus === "loading" ||
          generationStatus === "loading-pdf"
        }
        className="min-h-full"
        placeholder={
          id == null
            ? "No contact selected"
            : bio === ""
            ? "Empty bio detected"
            : undefined
        }
        value={currBio ?? ""}
        onChange={(e) => setCurrBio(e.target.value)}
      />
    </div>
  );
};

export const NewBioEditor = (props: {
  id: string;
  type: "user" | "company";
  onSelect: (id: string) => void;
  label: string;
}) => {
  const { id, type, onSelect, label } = props;

  const query = type === "user" ? useGetUserByIdQuery : useCompanyByIdQuery;
  const body = type === "user" ? { userId: id } : { id: id };

  const { data, loading } = query({
    variables: body,
  });

  const [bio, setBio] = useState<string>();
  const [name, setName] = useState<string>();

  useEffect(() => {
    if (loading) return;
    if (data == null) return;

    if (type === "user") {
      if (data?.userById == null) return;
      setBio(data?.userById?.bio ?? "");
      setName(data?.userById?.fullName ?? "Unknown");
    }

    if (type === "company") {
      setBio(data?.companyById?.bio ?? "");
      setName(data?.companyById?.dbaName ?? "Unknown");
    }

    () => {
      setBio(undefined);
      setName(undefined);
    };
  }, [data, setBio, setName, name, bio, loading, type]);

  return (
    <div className="flex flex-col rounded-sm h-full">
      <div className="flex flex-row items-center w-full bg-gray-100 p-2 rounded-t-sm">
        <h1 className="text-lg font-semibold">{label}</h1>
        <div className="ml-auto flex flex-row gap-x-1">
          {type === "user" && (
            <UserSelector
              selectedId={id}
              onSelect={onSelect}
              className="bg-white"
            />
          )}
          {type === "company" && (
            <CompanySelector
              selectedId={id}
              onSelect={onSelect}
              className="bg-white"
            />
          )}
        </div>
      </div>
      {bio != null && (
        <Textarea
          disabled
          className="ml-auto bg-white border-none shadow-none rounded-b-sm rounded-t-none h-full"
          placeholder={
            id.trim() === ""
              ? "No user/company selected"
              : bio === ""
              ? "Empty bio detected"
              : undefined
          }
          defaultValue={bio}
          onChange={(e) => setBio(e.target.value)}
        />
      )}
    </div>
  );
};

export default BioEditor;
