import Spinner from "./spinner";

const SuspenseLoader = () => {
  return (
    <div className="bg-paleblue w-screen h-screen flex flex-row items-center justify-center">
      <Spinner />
    </div>
  );
};

export default SuspenseLoader;
