import { Icon } from "../../ui/Icon";
import { Toolbar } from "../../ui/Toolbar";
import { useTextmenuCommands } from "./hooks/useTextmenuCommands";
import { useTextmenuStates } from "./hooks/useTextmenuStates";
import { Editor, useCurrentEditor } from "@tiptap/react";
import { memo, useMemo } from "react";

// import { FontFamilyPicker } from "./components/FontFamilyPicker";
import { FontSizePicker } from "./components/FontSizePicker";
import { useTextmenuContentTypes } from "./hooks/useTextmenuContentTypes";
import { ContentTypePicker } from "./components/ContentTypePicker";

import { EditLinkPopover } from "./components/EditLinkPopover";
import { Popover, PopoverContent, PopoverTrigger } from "@components/popover";
import { Surface } from "@components/tiptapEditor/ui/Surface";
import { ColorPicker } from "@components/tiptapEditor/panels";
import { AIDropdown } from "./components/AIDropdown";
import { FontFamilyPicker } from "./components/FontFamilyPicker";

// We memorize the button so each button is not rerendered
// on every editor state change
const MemoButton = memo(Toolbar.Button);
const MemoColorPicker = memo(ColorPicker);
const MemoFontFamilyPicker = memo(FontFamilyPicker);
const MemoFontSizePicker = memo(FontSizePicker);
const MemoContentTypePicker = memo(ContentTypePicker);

export type TextMenuProps = {
  allowAIDescriptionEditing?: boolean;
};

export const TextMenu = (props: { onClose?: () => void }) => {
  const { editor } = useCurrentEditor();

  const commands = useTextmenuCommands(editor);
  const states = useTextmenuStates(editor);
  const blockOptions = useTextmenuContentTypes(editor);

  if (!editor) {
    return null;
  }

  return (
    <div className="flex w-full items-center justify-center">
      <Toolbar.Wrapper>
        <AIDropdown onGenerateDescription={commands.onGenerateDescription} />

        <MemoContentTypePicker options={blockOptions} />
        <MemoFontFamilyPicker
          onChange={commands.onSetFont}
          value={states.currentFont || ""}
        />
        <MemoFontSizePicker
          onChange={commands.onSetFontSize}
          value={states.currentSize || ""}
        />
        <Toolbar.Divider />
        <MemoButton
          tooltip="Bold"
          tooltipShortcut={["Mod", "B"]}
          onClick={commands.onBold}
          active={states.isBold}
        >
          <Icon name="Bold" />
        </MemoButton>
        <MemoButton
          tooltip="Italic"
          tooltipShortcut={["Mod", "I"]}
          onClick={commands.onItalic}
          active={states.isItalic}
        >
          <Icon name="Italic" />
        </MemoButton>
        <MemoButton
          tooltip="Underline"
          tooltipShortcut={["Mod", "U"]}
          onClick={commands.onUnderline}
          active={states.isUnderline}
        >
          <Icon name="Underline" />
        </MemoButton>
        <MemoButton
          tooltip="Strikehrough"
          tooltipShortcut={["Mod", "X"]}
          onClick={commands.onStrike}
          active={states.isStrike}
        >
          <Icon name="Strikethrough" />
        </MemoButton>
        <MemoButton
          tooltip="Code"
          tooltipShortcut={["Mod", "E"]}
          onClick={commands.onCode}
          active={states.isCode}
        >
          <Icon name="Code" />
        </MemoButton>
        {/* <MemoButton tooltip="Code block" onClick={commands.onCodeBlock}>
          <Icon name="Code2" />
        </MemoButton> */}
        <EditLinkPopover onSetLink={commands.onLink} />
        <Popover>
          <PopoverTrigger asChild>
            <MemoButton
              active={!!states.currentHighlight}
              tooltip="Highlight text"
            >
              <Icon name="Highlighter" />
            </MemoButton>
          </PopoverTrigger>
          <PopoverContent side="top" sideOffset={8} asChild>
            <Surface className="p-1">
              <MemoColorPicker
                color={states.currentHighlight}
                onChange={commands.onChangeHighlight}
                onClear={commands.onClearHighlight}
              />
            </Surface>
          </PopoverContent>
        </Popover>
        <Popover>
          <PopoverTrigger asChild>
            <MemoButton active={!!states.currentColor} tooltip="Text color">
              <Icon name="Palette" />
            </MemoButton>
          </PopoverTrigger>
          <PopoverContent side="top" sideOffset={8} asChild>
            <Surface className="p-1">
              <MemoColorPicker
                color={states.currentColor}
                onChange={commands.onChangeColor}
                onClear={commands.onClearColor}
              />
            </Surface>
          </PopoverContent>
        </Popover>

        {/* <MemoButton
          tooltip="Subscript"
          tooltipShortcut={["Mod", "."]}
          onClick={commands.onSubscript}
          active={states.isSubscript}
        >
          <Icon name="Subscript" />
        </MemoButton>
        <MemoButton
          tooltip="Superscript"
          tooltipShortcut={["Mod", ","]}
          onClick={commands.onSuperscript}
          active={states.isSuperscript}
        >
          <Icon name="Superscript" />
        </MemoButton> */}
        <Toolbar.Divider />
        <MemoButton
          tooltip="Align left"
          tooltipShortcut={["Shift", "Mod", "L"]}
          onClick={commands.onAlignLeft}
          active={states.isAlignLeft}
        >
          <Icon name="AlignLeft" />
        </MemoButton>
        <MemoButton
          tooltip="Align center"
          tooltipShortcut={["Shift", "Mod", "E"]}
          onClick={commands.onAlignCenter}
          active={states.isAlignCenter}
        >
          <Icon name="AlignCenter" />
        </MemoButton>
        <MemoButton
          tooltip="Align right"
          tooltipShortcut={["Shift", "Mod", "R"]}
          onClick={commands.onAlignRight}
          active={states.isAlignRight}
        >
          <Icon name="AlignRight" />
        </MemoButton>
        <MemoButton
          tooltip="Justify"
          tooltipShortcut={["Shift", "Mod", "J"]}
          onClick={commands.onAlignJustify}
          active={states.isAlignJustify}
        >
          <Icon name="AlignJustify" />
        </MemoButton>
        {props.onClose && (
          <>
            <Toolbar.Divider />
            <MemoButton tooltip="Close" onClick={props.onClose}>
              <Icon name="X" />
            </MemoButton>
          </>
        )}
      </Toolbar.Wrapper>
    </div>
  );
};
