import { File, FileText, User } from "@phosphor-icons/react";
import { buttonVariants } from "./button";
import { useNavigate, Link } from "react-router-dom";
import { useMemo } from "react";
import { Pencil } from "@phosphor-icons/react/dist/ssr";

const BuilderNavigator = () => {
  const navigate = useNavigate();

  const currentPath = window.location.pathname;

  const items = useMemo(
    () => [
      {
        label: "Petitions",
        icon: FileText,
        path: "/admin/petition-builder",
      },
      {
        label: "Entities",
        icon: User,
        path: "/admin/entities",
      },
      {
        label: "Exhibit Repo",
        icon: File,
        path: "/admin/exhibit-repo",
      },
      {
        label: "Letter",
        icon: Pencil,
        path: "/admin/draft",
      },
    ],
    []
  );

  return (
    <div className="w-full flex flex-row border-b items-center px-3 pb-2 gap-x-2">
      {items.map((item) => (
        <Link
          className={`flex flex-row gap-x-1 ${currentPath.includes(item.path) ? buttonVariants({ variant: "tab-active", size: 'tab' }) : buttonVariants({ variant: "tab", size: 'tab' })}`}
          to={item.path}
          key={item.path}
        >
          <item.icon size={14} /> {item.label}
        </Link>
      ))}
    </div>
  );
};

export default BuilderNavigator;
