export const HighEnumerationDescription = (
  <div>
    <p className="mb-3">Let’s get started on your case! We want to demonstrate that you're a higher earner than others performing in the same role in your field.</p>
    <p>(For example, you are considered a top earner in your country for your role.) This is what we call the 'High Remuneration' criterion.</p>
  </div>
)

export const CriticalRoleDescription = (
  <div>
    <p className="mb-3">Now, let's highlight your career impact. We want to show that you've played a critical or essential role in organizations with a stellar reputation.</p>
    <p>Have you been the go-to person for important projects, led key initiatives, or made significant contributions to your company's technology, strategy, or products? If so, we'd love to see evidence of that.</p>
  </div>
)

export const CriticalRoleCodeContribution = (
  <div>
    <p className="mb-3">Code contributor page which clearly shows your name — the higher the code contributions, the better (the one with those cool graphs!)</p>
    <p className="">GitHub profile page and any pages showing your code project ownership — we want to see you’re an admin / and your pulls and commits</p>
  </div>
)

export const CriticalRoleScholarlyArticle = (
  <div>
    <p className="mb-3">Scholarly articles or papers published in academic journals/conferences</p>
    <p className="text-sm">*Please upload the publication files if it is not publicly available (behind a paywall, etc.)</p>
  </div>
)

export const CriticalRolePatents = (
  <div>
    <p className="mb-3">Any patents you’ve filed in this role where you’re listed as inventor (preferably a Google Patent URL)</p>
  </div>
)

export const CriticalRoleCustomerContracts = (
  <div>
    <p className="mb-3">Contracts you’ve signed on behalf of the company</p>
  </div>
)

export const CriticalRoleInternalDocuments = (
  <div>
    <p className="mb-3"><b>Presentations</b> you've created (from Notion, slide decks, fundraising decks etc.) where you are listed as the owner/author/ or as leading the initiative or project.</p>
    <p className="mb-3"><b>Dashboards</b> illustrating your achievements in real numbers (e.g. a dashboard showing how many users you’ve gained, how many downloads you’ve received, how many customers you’ve served, how many people have interacted with your technology, how much revenue you’ve generated, etc.)</p>
    <p>If your name isn’t visible, that’s ok! We can draft a document confirming this was you.</p>
  </div>
)

export const CriticalRoleAcademicContractProposal = (
  <div>
    <p className="mb-3">Contracts / Grant Proposals / or Budget Allocations you were involved in as a researcher</p>
  </div>
)

export const CriticalRoleAcademicPapers = (
  <div>
    <p className="mb-3">Papers you’ve published during your academic role </p>
  </div>
)

export const CriticalRoleDistinguishedReputation = (
  <div>
    <p className="mb-3">Please help us to show that the organization where you held/hold a critical role has a "distinguished reputation."  This means that your organization is recognized by third parties as being reputable. Please attach documents such as:</p>
    <ul className="list-disc pl-5 mb-3">
      <li><b>User stats</b> (like Google Analytics or waitlist numbers)</li>
      <li><b>Impressive customer contracts</b> (high revenue contracts or contracts with important players in your field)</li>
      <li><b>Company revenue info</b> (Stripe Dashboard or customer contracts) </li>
      <li><b>Funding documentation</b> to show funds raised from well-regarded sources (prominent VCs, Angels, or government grants)</li>
    </ul>
    <div className="text-sm">
      <p className="mb-2">Don't worry if you don't have all of these – even a few strong pieces can paint a picture of your critical role and organization’s distinguished reputation. Upload what you can, and let your achievements shine!</p>
      <p>We’ll gather publicly available information to show the company’s reputation (press, awards).</p>
    </div>
  </div>
)

export const MembershipDocumentationDescription = (
  <div>
    <p className="mb-3">We're interested in your memberships in exclusive professional associations. This is what we call the 'Membership' criteria.</p>

    <p className="mb-2">Are you part of any select groups in your field that only admit members based on outstanding achievements? These are the kind of associations where recognized experts in your discipline decide who gets in. If you're in any of these elite membership organizations, we'd love to know!</p>
  </div>
)
// export const AuthorshipJournalArticleDescription = (
//   <div>
//     <p className=""></p>
//   </div>
// )
// export const AuthorshipGoogleScholarPageDescription = (
//   <div>
//     <p className=""></p>
//   </div>
// )
// export const AuthorshipConferenceDescription = (
//   <div>
//     <p className=""></p>
//   </div>
// )
export const AuthorshipMajorPublicationDescription = (
  <div className="flex flex-col gap-3">
    <p>We're looking for evidence of your authorship in your professional field. This is what we call the 'Authorship' criteria.</p>
    <p>Have you shared your expertise through writing or presentations? We'd love to see examples of your work. </p>
    <p>Remember, we're interested in both written pieces and presentations. Don't worry if you haven't published in all these places – even one or two solid examples can highlight your contributions to your field.</p>
    <p>For each item, please provide a direct link or a full-page screenshot that includes the URL. This helps us verify your work.</p>
  </div>
)

export const JudgingDescription = (
  <div className="flex flex-col gap-3">
    <p>Let's talk about your expertise in action! We're excited to see how you've been a judge in your field. This is what we call the 'Judging' criteria.</p>
    <p>Have you been invited to evaluate the work of others in your profession? Maybe you've been on a panel at a hackathon, judged a prestigious competition, or have participated in academic peer review? These experiences show that your opinion is highly valued in your field.</p>
  </div>
)

export const AwardDescription = (
  <div className="flex flex-col gap-3">
    <p>Remember, it's not just about having an award – we're interested in understanding its significance in your field. Even if you have just one notable award, it can speak volumes about your recognition in the field!</p>
  </div>
)

export const OriginalContributionEvidenceDescription = (
  <div className="flex flex-col gap-3">
    <p>Could you provide any of the following:</p>
    <ul className="list-disc pl-5">
      <li><b>Published materials</b> discussing the significance of your original work (think press articles, reviews, or reports)</li>
      <li>Evidence showing <b>your work has been widely cited</b> or referenced by others in your industry</li>
      <li><b>Patents</b> or <b>licenses</b> stemming from your innovations</li>
      <li>Proof that your work has been <b>commercially adopted</b> or used in the real world</li>
      <li>Proof of a <b>significant number of downloads</b></li>
      <li>Proof that a <b>large number of people are using your technology</b> (think in the thousands, and tens of thousands, if not more!)</li>
      <li>Proof that you received any industry <b>awards or prizes</b>.</li>
    </ul>
    <p>Don't worry if you don't have all of these. Upload what you have and let's showcase your contributions.</p>
  </div>
)

export const PressArticleDescription = (
  <div>
    <p className="">We’ve got this one covered! We’ll find all publicly available press links about you. If there’s a publication or print press you’d like to include, please share the URL or upload a document below. </p>
  </div>
)
