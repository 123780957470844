import {
  useCompanyByIdLazyQuery,
  useGetEntityLazyQuery,
  useGetUserByIdLazyQuery,
} from "@codegen/index";
import { EntityTypes, VisaClassType } from "@codegen/schema";
import ArchetypeSelectorMemo from "@components/archetypeSelector";
import { NewBioEditor } from "@components/bioEditor";
import { Combobox } from "@components/combobox";
import CompanySelector from "@components/companySelector";
import { Input } from "@components/input";
import { Textarea } from "@components/textArea";
import VisaClassSelector from "@components/visaClassSelector";
import { useCallback, useContext, useMemo, useState } from "react";
import {
  default as DraftContext,
  DraftContextType,
} from "../../providers/draftProvider";
import { MultiSelect } from "@components/multiSelect";
import EntitySelector from "@components/entitySelectorByType";
import { Button } from "@components/button";
import { FormStatus } from "@utils/types";
import { api } from "@utils/api";
import Spinner from "@components/spinner";
import { Square, CheckSquare } from "@phosphor-icons/react";
import { useJwt } from "@utils/hooks";

const LetterPreview = (props: { letter?: string; disabled?: boolean }) => {
  return (
    <Textarea
      className="min-h-full"
      placeholder="Generate a letter to edit it here."
      defaultValue={props.letter}
      disabled={props.disabled ?? false}
    />
  );
};

const LeftBar = () => {
  const { data, setData } = useContext(DraftContext);
  const [companyById] = useCompanyByIdLazyQuery();
  const [entityById] = useGetEntityLazyQuery();

  const updateBaseInputKey = useCallback(
    (
      key: keyof DraftContextType["baseInputs"],
      value:
        | string
        | VisaClassType
        | { id: string; name: string; bio: string; title: string }
    ) => {
      setData({
        ...data,
        baseInputs: {
          ...data.baseInputs,
          [key]: value,
        },
      });
    },
    [data, setData]
  );

  const letterTypeSelector = useMemo(() => {
    const options = [
      {
        label: "Critical Role",
        value: "critical-role",
      },
      {
        label: "Original Contributions",
        value: "original-contributions",
      },
      {
        label: "Critical Role + Original Contributions Letter",
        value: "cr-oc-letter",
      },
      {
        label: "Advisory Opinion Letter",
        value: "ao-letter",
      },
      {
        label: "Membership Letter",
        value: "membership",
      },
      {
        label: "Award / VC Letter",
        value: "award/vc",
      },
      {
        label: "TN Support Letter",
        value: "tn-support-letter",
      },
      {
        label: "EV Letter",
        value: "ev-letter",
      },
    ];

    return (
      <div className="flex flex-col gap-y-1">
        <h1 className="text-xs text-gray-400 font-semibold">Letter Type</h1>
        <Combobox
          defaultValue={data.baseInputs.letterType}
          options={options}
          onSelect={(x) => updateBaseInputKey("letterType", x)}
          placeholder="Select a letter type"
          className="bg-white"
        />
      </div>
    );
  }, [updateBaseInputKey, data]);

  const visaClassSelector = useMemo(() => {
    return (
      <div className="flex flex-col gap-y-1">
        <h1 className="text-xs text-gray-400 font-semibold">Visa Class</h1>
        <VisaClassSelector
          selectedType={data.baseInputs.visaClass.replaceAll("-", "_")}
          onSelect={(x) =>
            updateBaseInputKey("visaClass", x.replaceAll("_", "-"))
          }
          className="bg-white"
        />
      </div>
    );
  }, [updateBaseInputKey, data]);

  const archetypeSelector = useMemo(() => {
    return (
      <div className="flex flex-col gap-y-1">
        <h1 className="text-xs text-gray-400 font-semibold">Archetype</h1>
        <ArchetypeSelectorMemo
          selectedType={data.baseInputs.archetype}
          onSelect={(x) => updateBaseInputKey("archetype", x)}
          className="bg-white"
        />
      </div>
    );
  }, [updateBaseInputKey, data]);

  const doSelectSignatoryEmployer = useCallback(
    async (x: string) => {
      const { data: companyData } = await companyById({
        variables: {
          id: x,
        },
      });

      if (companyData?.companyById == null) return;

      setData({
        ...data,
        baseInputs: {
          ...data.baseInputs,
          signatoryEmployer: {
            id: companyData.companyById.id,
            name: companyData.companyById.dbaName,
          },
        },
      });
    },
    [companyById, data, setData]
  );

  const beneficiaryTitleInput = useMemo(() => {
    return (
      <div className="flex flex-col gap-y-1">
        <h1 className="text-xs text-gray-400 font-semibold">
          Beneficiary Title
        </h1>
        <Input
          defaultValue={data.baseInputs.beneficiary.title}
          onChange={(e) =>
            updateBaseInputKey("beneficiary", {
              ...data.baseInputs.beneficiary,
              title: e.target.value,
            })
          }
          className="bg-white max-w-[220px]"
        />
      </div>
    );
  }, [updateBaseInputKey, data]);

  const signatoryTitleInput = useMemo(() => {
    return (
      <div className="flex flex-col gap-y-1">
        <h1 className="text-xs text-gray-400 font-semibold">Signatory Title</h1>
        <Input
          defaultValue={data.baseInputs.signatory.title}
          onChange={(e) =>
            updateBaseInputKey("signatory", {
              ...data.baseInputs.signatory,
              title: e.target.value,
            })
          }
          className="bg-white max-w-[220px]"
        />
      </div>
    );
  }, [updateBaseInputKey, data]);

  const signatoryEmployerSelector = useMemo(() => {
    return (
      <div className="flex flex-col gap-y-1">
        <h1 className="text-xs text-gray-400 font-semibold">
          Signatory Employer
        </h1>
        <CompanySelector
          selectedId={data.baseInputs.signatoryEmployer?.id ?? ""}
          onSelect={doSelectSignatoryEmployer}
          className="bg-white"
        />
      </div>
    );
  }, [data, doSelectSignatoryEmployer]);

  const fieldOfEndeavorInput = useMemo(() => {
    return (
      <div className="flex flex-col gap-y-1">
        <h1 className="text-xs text-gray-400 font-semibold">
          Field of Endeavor
        </h1>
        <Input
          defaultValue={data.baseInputs.fieldOfEndeavor}
          onChange={(e) =>
            updateBaseInputKey("fieldOfEndeavor", e.target.value)
          }
          className="bg-white max-w-[220px]"
        />
      </div>
    );
  }, [updateBaseInputKey, data]);

  const ocLetterOptions = useMemo(() => {
    return (
      <div className="flex flex-col gap-y-1 mt-2">
        <h1 className="text-sm text-gray-500 font-semibold">
          Original Contributions Letter Options
        </h1>

        <h1 className="text-xs text-gray-400 font-semibold">Documents</h1>
        <Input
          type="file"
          multiple
          onChange={(e) =>
            setData({
              ...data,
              ocLetterOptions: {
                ...(data.ocLetterOptions ?? {}),
                docs: e.target.files ?? null,
              },
            })
          }
          className="items-center h-fit max-w-[220px]"
        />
      </div>
    );
  }, [data, setData]);

  const crOcLetterOptions = useMemo(() => {
    return (
      <div className="flex flex-col gap-y-1 mt-2">
        <h1 className="text-sm text-gray-500 font-semibold">
          Critical Role + Original Contributions Letter Options
        </h1>

        <h1 className="text-xs text-gray-400 font-semibold">Documents</h1>
        <Input
          type="file"
          multiple
          onChange={(e) =>
            setData({
              ...data,
              ocLetterOptions: {
                ...(data.ocLetterOptions ?? {}),
                docs: e.target.files ?? null,
              },
            })
          }
          className="items-center h-fit max-w-[220px]"
        />
      </div>
    );
  }, [data, setData]);

  const aoLetterOptions = useMemo(() => {
    return (
      <div className="flex flex-col gap-y-1 mt-2">
        <h1 className="text-sm text-gray-500 font-semibold">
          AO Letter Options
        </h1>

        <h1 className="text-xs text-gray-400 font-semibold">Criteria</h1>
        <MultiSelect
          className="bg-white"
          options={[
            {
              label: "Critical Role",
              value: "critical-role",
            },
            {
              label: "Original Contribution",
              value: "original-contribution",
            },
            {
              label: "Authorship",
              value: "authorship",
            },
            {
              label: "Judging",
              value: "judging",
            },
            {
              label: "Membership",
              value: "membership",
            },
            {
              label: "Awards",
              value: "awards",
            },
            {
              label: "Press",
              value: "press",
            },
          ]}
          selected={data.aoLetterOptions?.criteria ?? []}
          onChange={(x) =>
            setData({
              ...data,
              aoLetterOptions: {
                ...(data.aoLetterOptions ?? {}),
                criteria: x as string[],
              },
            })
          }
        />
      </div>
    );
  }, [data, setData]);

  const membershipLetterOptions = useMemo(() => {
    const onSelectMembership = async (x: string) => {
      const { data: entityData } = await entityById({
        variables: {
          id: x,
          entityType: EntityTypes.Membership,
        },
      });

      if (entityData?.additionalEntityById == null) return;
      const entity = entityData.additionalEntityById;
      if (entity.data == null) {
        console.log("entity data is null");
        return;
      }

      try {
        const entityDatum = JSON.parse(entity.data);
        const { description, selection_criteria } = entityDatum;

        setData({
          ...data,
          membershipLetterOptions: {
            description: description ?? "",
            selectivity: selection_criteria ?? "",
            entityId: x,
          },
        });
      } catch (e) {
        console.log("failed to parse entity data");
        return;
      }
    };

    return (
      <div className="flex flex-col gap-y-1 mt-2">
        <h1 className="text-sm text-gray-500 font-semibold">
          Membership Letter Options
        </h1>

        <h1 className="text-xs text-gray-400 font-semibold">
          Membership Entity
        </h1>
        <EntitySelector
          type={EntityTypes.Membership}
          className="bg-white"
          onSelect={(x) => onSelectMembership(x)}
        />
      </div>
    );
  }, [data, entityById, setData]);

  const awardVcLetterOptions = useMemo(() => {
    const onSelectVc = async (x: string) => {
      const { data: entityData } = await entityById({
        variables: {
          id: x,
          entityType: EntityTypes.Vc,
        },
      });

      if (entityData?.additionalEntityById == null) return;
      const entity = entityData.additionalEntityById;
      if (entity.data == null) {
        console.log("entity data is null");
        return;
      }

      try {
        const entityDatum = JSON.parse(entity.data);
        const { selection_criteria } = entityDatum;

        setData({
          ...data,
          awardVcLetterOptions: {
            ...(data.awardVcLetterOptions ?? {
              fundingAmount: "",
              fundingYear: "",
            }),
            entityId: x,
            selectionCriteria: selection_criteria ?? "",
          },
        });
      } catch (e) {
        console.log("failed to parse entity data");
        return;
      }
    };

    return (
      <div className="flex flex-col gap-y-1 mt-2 overflow-y-scroll">
        <h1 className="text-sm text-gray-500 font-semibold">
          Award/VC Letter Options
        </h1>

        <h1 className="text-xs text-gray-400 font-semibold">VC Entity</h1>
        <EntitySelector
          type={EntityTypes.Vc}
          className="bg-white"
          onSelect={(x) => onSelectVc(x)}
        />

        <h1 className="text-xs text-gray-400 font-semibold">Funding Year</h1>
        <Input
          defaultValue={data.awardVcLetterOptions?.fundingYear}
          placeholder="2021"
          onChange={(e) =>
            setData({
              ...data,
              awardVcLetterOptions: {
                ...(data.awardVcLetterOptions ?? {
                  fundingAmount: "",
                  fundingYear: "",
                  entityId: "",
                  selectionCriteria: "",
                }),
                fundingYear: e.target.value,
              },
            })
          }
          className="bg-white max-max-w-[220px]"
        />

        <h1 className="text-xs text-gray-400 font-semibold">Funding Amount</h1>
        <Input
          defaultValue={data.awardVcLetterOptions?.fundingAmount}
          placeholder="e.g. $1,000,000"
          onChange={(e) =>
            setData({
              ...data,
              awardVcLetterOptions: {
                ...(data.awardVcLetterOptions ?? {
                  fundingYear: "",
                  entityId: "",
                  selectionCriteria: "",
                }),
                fundingAmount: e.target.value,
              },
            })
          }
          className="bg-white max-w-[220px]"
        />
      </div>
    );
  }, [data, entityById, setData]);

  const tnLetterOptions = useMemo(() => {
    const defaultOptions = {
      offerLetter: null,
      resume: null,
      tnCategory: "Engineer",
      petitioner: {
        id: "",
        bio: "",
      },
    };

    return (
      <div className="flex flex-col gap-y-1 mt-2">
        <h1 className="text-sm text-gray-500 font-semibold">
          TN Support Letter Options
        </h1>

        <h1 className="text-xs text-gray-400 font-semibold">Resume</h1>
        <Input
          type="file"
          onChange={(e) =>
            setData({
              ...data,
              tnLetterOptions: {
                ...(data.tnLetterOptions ?? defaultOptions),
                resume: e.target.files?.[0] ?? null,
              },
            })
          }
          className="items-center h-fit max-w-[220px]"
        />

        <h1 className="text-xs text-gray-400 font-semibold">Offer Letter</h1>
        <Input
          type="file"
          onChange={(e) =>
            setData({
              ...data,
              tnLetterOptions: {
                ...(data.tnLetterOptions ?? defaultOptions),
                offerLetter: e.target.files?.[0] ?? null,
              },
            })
          }
          className="items-center h-fit max-w-[220px]"
        />

        <h1 className="text-xs text-gray-400 font-semibold">TN Category</h1>
        <Input
          defaultValue="Engineer"
          disabled
          className="bg-white max-w-[220px]"
        />
      </div>
    );
  }, [data, setData]);

  const evLetterOptions = useMemo(() => {
    return (
      <div className="flex flex-col gap-y-1">
        <h1 className="text-xs text-gray-400 font-semibold">Salary</h1>
        <Input
          defaultValue={data.evLetterOptions?.salary}
          onChange={(e) =>
            setData({
              ...data,
              evLetterOptions: {
                ...(data.evLetterOptions ?? {}),
                salary: e.target.value,
                equity: data.evLetterOptions?.equity ?? "",
                previousRole: data.evLetterOptions?.previousRole ?? false,
              },
            })
          }
          className="bg-white max-w-[220px]"
        />

        <h1 className="text-xs text-gray-400 font-semibold">Equity</h1>
        <Input
          defaultValue={data.evLetterOptions?.equity}
          onChange={(e) =>
            setData({
              ...data,
              evLetterOptions: {
                ...(data.evLetterOptions ?? {}),
                salary: data.evLetterOptions?.salary ?? "",
                equity: e.target.value,
                previousRole: data.evLetterOptions?.previousRole ?? false,
              },
            })
          }
          className="bg-white max-w-[220px]"
        />

        <h1 className="text-xs text-gray-400 font-semibold">Previous Role</h1>
        <button
          onClick={() =>
            setData({
              ...data,
              evLetterOptions: {
                ...(data.evLetterOptions ?? {}),
                salary: data.evLetterOptions?.salary ?? "",
                equity: data.evLetterOptions?.equity ?? "",
                previousRole: !data.evLetterOptions?.previousRole,
              },
            })
          }
          className="max-w-[220px]"
        >
          {data.evLetterOptions?.previousRole ? (
            <CheckSquare size={24} className="text-gray-400" />
          ) : (
            <Square size={24} className="text-gray-400" />
          )}
        </button>
      </div>
    );
  }, [data, setData]);

  return (
    <div className="w-1/4 flex flex-col gap-y-2 bg-gray-100 p-4 rounded-sm border">
      {letterTypeSelector}
      {data.baseInputs.letterType !== "tn-support-letter" && (
        <>
          {data.baseInputs.letterType === "ev-letter" ? (
            <>
              {beneficiaryTitleInput}
              {signatoryTitleInput}
            </>
          ) : (
            <>
              {visaClassSelector}
              {archetypeSelector}
              {beneficiaryTitleInput}
              {signatoryTitleInput}
              {signatoryEmployerSelector}
              {fieldOfEndeavorInput}

              {data.baseInputs.letterType === "original-contributions" &&
                ocLetterOptions}
              {data.baseInputs.letterType === "cr-oc-letter" &&
                crOcLetterOptions}
            </>
          )}
          {data.baseInputs.letterType === "ao-letter" && aoLetterOptions}
          {data.baseInputs.letterType === "membership" &&
            membershipLetterOptions}
          {data.baseInputs.letterType === "award/vc" && awardVcLetterOptions}
          {data.baseInputs.letterType === "ev-letter" && evLetterOptions}
        </>
      )}
      {data.baseInputs.letterType === "tn-support-letter" && tnLetterOptions}
    </div>
  );
};

const MiddleBar = () => {
  const token = useJwt();
  const { data, setData, status, setStatus, setGeneratedLetter } =
    useContext(DraftContext);

  const [userById] = useGetUserByIdLazyQuery();
  const [companyById] = useCompanyByIdLazyQuery();

  const doSelectBeneficiary = useCallback(
    async (x: string) => {
      const { data: queryData } = await userById({
        variables: {
          userId: x,
        },
      });

      if (queryData?.userById == null) return;

      setData({
        ...data,
        baseInputs: {
          ...data.baseInputs,
          beneficiary: {
            id: queryData.userById.id,
            name: queryData.userById.fullName,
            bio: queryData.userById.bio ?? "",
            title: data.baseInputs.beneficiary.title,
          },
        },
      });
    },
    [userById, data, setData]
  );

  const doSelectSignatory = useCallback(
    async (x: string) => {
      const { data: queryData } = await userById({
        variables: {
          userId: x,
        },
      });

      if (queryData?.userById == null) return;

      setData({
        ...data,
        baseInputs: {
          ...data.baseInputs,
          signatory: {
            id: queryData.userById.id,
            name: queryData.userById.fullName,
            bio: queryData.userById.bio ?? "",
            title: data.baseInputs.signatory.title,
          },
        },
      });
    },
    [userById, data, setData]
  );

  const doSelectCompany = useCallback(
    async (x: string) => {
      const { data: queryData } = await companyById({
        variables: {
          id: x,
        },
      });

      if (queryData?.companyById == null) return;

      setData({
        ...data,
        baseInputs: {
          ...data.baseInputs,
          company: {
            id: queryData.companyById.id,
            name: queryData.companyById.dbaName,
            bio: queryData.companyById.bio ?? "",
          },
        },
      });
    },
    [companyById, data, setData]
  );

  const isDraftDisabled = useMemo(() => {
    if (data.baseInputs.letterType === "tn-support-letter") {
      return (
        data.tnLetterOptions?.offerLetter == null ||
        data.tnLetterOptions?.resume == null
      );
    }
    if (data.baseInputs.letterType === "ev-letter") {
      return (
        data.evLetterOptions?.salary == null ||
        data.baseInputs.beneficiary.id === "" ||
        data.baseInputs.signatory.id === "" ||
        data.baseInputs.company.id === "" ||
        data.baseInputs.company.bio === "" ||
        data.baseInputs.beneficiary.title === "" ||
        data.baseInputs.signatory.title === ""
      );
    }

    // any base input is null
    if (
      data.baseInputs.visaClass === "" ||
      data.baseInputs.beneficiary.id === "" ||
      data.baseInputs.beneficiary.bio === "" ||
      data.baseInputs.beneficiary.title === "" ||
      data.baseInputs.signatory.id === "" ||
      data.baseInputs.signatory.bio === "" ||
      data.baseInputs.signatory.title === "" ||
      data.baseInputs.company.id === "" ||
      data.baseInputs.company.bio === "" ||
      data.baseInputs.fieldOfEndeavor === "" ||
      data.baseInputs.archetype === "" ||
      data.baseInputs.signatoryEmployer.id === "" ||
      data.baseInputs.signatoryEmployer.name === ""
    ) {
      return true;
    }

    if (data.baseInputs.letterType === "membership") {
      return data.membershipLetterOptions?.entityId == null;
    }

    if (data.baseInputs.letterType === "award/vc") {
      return data.awardVcLetterOptions?.entityId == null;
    }

    if (data.baseInputs.letterType === "ao-letter") {
      return data.aoLetterOptions?.criteria.length === 0;
    }

    return false;
  }, [data]);

  const doGenerateLetter = useCallback(async () => {
    setStatus("uploading");

    let success = false;
    let resData: Response | undefined;

    if (data.baseInputs.letterType === "critical-role") {
      const { success: s, data: d } = await api.lettergen.crLetter(
        {
          beneficiaryBio: data.baseInputs.beneficiary.bio,
          beneficiaryName: data.baseInputs.beneficiary.name,
          signatoryBio: data.baseInputs.signatory.bio,
          signatoryName: data.baseInputs.signatory.name,
          signatoryTitle: data.baseInputs.signatory.title,
          companyBio: data.baseInputs.company.bio,
          companyName: data.baseInputs.company.name,
          fieldOfEndeavor: data.baseInputs.fieldOfEndeavor,
          visaCategory: data.baseInputs.visaClass,
          archetype: data.baseInputs.archetype,
          signatoryEmployer: data.baseInputs.signatoryEmployer.name,
          howTheyMet: data.baseInputs.howTheyMet,
        },
        token
      );

      success = s;
      resData = d;
    }

    if (data.baseInputs.letterType === "original-contributions") {
      const { success: s, data: d } = await api.lettergen.ocLetter(
        {
          beneficiaryName: data.baseInputs.beneficiary.name,
          companyName: data.baseInputs.company.name,
          signatoryBio: data.baseInputs.signatory.bio,
          signatoryName: data.baseInputs.signatory.name,
          signatoryTitle: data.baseInputs.signatory.title,
          fieldOfEndeavor: data.baseInputs.fieldOfEndeavor,
          visaCategory: data.baseInputs.visaClass,
          archetype: data.baseInputs.archetype,
          signatoryEmployer: data.baseInputs.signatoryEmployer.name,
          howTheyMet: data.baseInputs.howTheyMet,
          additionalInfo: data.baseInputs.additionalInfo,
          docs: data.ocLetterOptions?.docs ?? null,
        },
        token
      );
      success = s;
      resData = d;
    }

    if (data.baseInputs.letterType === "cr-oc-letter") {
      const { success: s, data: d } = await api.lettergen.crOcLetter(
        {
          beneficiaryBio: data.baseInputs.beneficiary.bio,
          beneficiaryName: data.baseInputs.beneficiary.name,
          signatoryBio: data.baseInputs.signatory.bio,
          signatoryName: data.baseInputs.signatory.name,
          signatoryTitle: data.baseInputs.signatory.title,
          companyBio: data.baseInputs.company.bio,
          companyName: data.baseInputs.company.name,
          fieldOfEndeavor: data.baseInputs.fieldOfEndeavor,
          visaCategory: data.baseInputs.visaClass,
          archetype: data.baseInputs.archetype,
          signatoryEmployer: data.baseInputs.signatoryEmployer.name,
          howTheyMet: data.baseInputs.howTheyMet,
          additionalInfo: data.baseInputs.additionalInfo,
          docs: data.ocLetterOptions?.docs ?? null,
        },
        token
      );
      success = s;
      resData = d;
    }

    if (data.baseInputs.letterType === "ao-letter") {
      const { success: s, data: d } = await api.lettergen.aoLetter(
        {
          beneficiaryBio: data.baseInputs.beneficiary.bio,
          beneficiaryName: data.baseInputs.beneficiary.name,
          signatoryBio: data.baseInputs.signatory.bio,
          signatoryName: data.baseInputs.signatory.name,
          signatoryTitle: data.baseInputs.signatory.title,
          fieldOfEndeavor: data.baseInputs.fieldOfEndeavor,
          visaCategory: data.baseInputs.visaClass,
          archetype: data.baseInputs.archetype,
          signatoryEmployer: data.baseInputs.signatoryEmployer.name,
          howTheyMet: data.baseInputs.howTheyMet,
          criteria: data.aoLetterOptions?.criteria ?? [],
        },
        token
      );
      success = s;
      resData = d;
    }

    if (data.baseInputs.letterType === "membership") {
      const { success: s, data: d } = await api.lettergen.membershipLetter(
        {
          beneficiaryBio: data.baseInputs.beneficiary.bio,
          beneficiaryName: data.baseInputs.beneficiary.name,
          signatoryBio: data.baseInputs.signatory.bio,
          signatoryName: data.baseInputs.signatory.name,
          signatoryTitle: data.baseInputs.signatory.title,
          fieldOfEndeavor: data.baseInputs.fieldOfEndeavor,
          visaCategory: data.baseInputs.visaClass,
          archetype: data.baseInputs.archetype,
          signatoryEmployer: data.baseInputs.signatoryEmployer.name,
          howTheyMet: data.baseInputs.howTheyMet,
          membershipDescription:
            data.membershipLetterOptions?.description ?? "",
          membershipSelectionCriteria:
            data.membershipLetterOptions?.selectivity ?? "",
        },
        token
      );
      success = s;
      resData = d;
    }

    if (data.baseInputs.letterType === "award/vc") {
      const { success: s, data: d } = await api.lettergen.awardVcLetter(
        {
          beneficiaryBio: data.baseInputs.beneficiary.bio,
          beneficiaryName: data.baseInputs.beneficiary.name,
          signatoryBio: data.baseInputs.signatory.bio,
          signatoryName: data.baseInputs.signatory.name,
          signatoryTitle: data.baseInputs.signatory.title,
          fieldOfEndeavor: data.baseInputs.fieldOfEndeavor,
          visaCategory: data.baseInputs.visaClass,
          signatoryEmployer: data.baseInputs.signatoryEmployer.name,
          fundingAmount: data.awardVcLetterOptions?.fundingAmount ?? "",
          fundingYear: data.awardVcLetterOptions?.fundingYear ?? "",
          vcSelectionCriteria:
            data.awardVcLetterOptions?.selectionCriteria ?? "",
          companyBio: data.baseInputs.company.bio,
          companyName: data.baseInputs.company.name,
        },
        token
      );
      success = s;
      resData = d;
    }

    if (data.baseInputs.letterType === "tn-support-letter") {
      const { success: s, data: d } = await api.lettergen.tnSupportLetter(
        {
          companyBio: data.baseInputs.company.bio,
          resume: data.tnLetterOptions?.resume ?? null,
          offerLetter: data.tnLetterOptions?.offerLetter ?? null,
          tnCategory: data.tnLetterOptions?.tnCategory ?? "",
        },
        token
      );
      success = s;
      resData = d;
    }

    if (data.baseInputs.letterType === "ev-letter") {
      const { success: s, data: d } = await api.lettergen.evLetter(
        {
          beneficiaryName: data.baseInputs.beneficiary.name,
          beneficiaryTitle: data.baseInputs.beneficiary.title,
          signatoryName: data.baseInputs.signatory.name,
          signatoryTitle: data.baseInputs.signatory.title,
          company: data.baseInputs.company.name,
          salary: data.evLetterOptions?.salary ?? "",
          equity: data.evLetterOptions?.equity ?? "",
          previousRole: data.evLetterOptions?.previousRole ?? false,
          companyBio: data.baseInputs.company.bio,
        },
        token
      );
      success = s;
      resData = d;
    }

    console.log("success", success);
    console.log("resData", resData);

    if (!success || resData == null) {
      setStatus("error");
      return;
    }

    const text = await resData.text();
    setGeneratedLetter(text);
    setStatus("idle");
  }, [
    data.aoLetterOptions?.criteria,
    data.awardVcLetterOptions?.fundingAmount,
    data.awardVcLetterOptions?.fundingYear,
    data.awardVcLetterOptions?.selectionCriteria,
    data.baseInputs.additionalInfo,
    data.baseInputs.archetype,
    data.baseInputs.beneficiary.bio,
    data.baseInputs.beneficiary.name,
    data.baseInputs.beneficiary.title,
    data.baseInputs.company.bio,
    data.baseInputs.company.name,
    data.baseInputs.fieldOfEndeavor,
    data.baseInputs.howTheyMet,
    data.baseInputs.letterType,
    data.baseInputs.signatory.bio,
    data.baseInputs.signatory.name,
    data.baseInputs.signatory.title,
    data.baseInputs.signatoryEmployer.name,
    data.baseInputs.visaClass,
    data.evLetterOptions?.equity,
    data.evLetterOptions?.previousRole,
    data.evLetterOptions?.salary,
    data.membershipLetterOptions?.description,
    data.membershipLetterOptions?.selectivity,
    data.ocLetterOptions?.docs,
    data.tnLetterOptions?.offerLetter,
    data.tnLetterOptions?.resume,
    data.tnLetterOptions?.tnCategory,
    setGeneratedLetter,
    setStatus,
    token,
  ]);

  return (
    <div className="flex flex-col gap-y-1 w-full">
      <div className="w-full flex flex-row gap-x-2 rounded-sm border items-center p-2">
        <Button
          className="ml-auto"
          variant={"accent"}
          disabled={isDraftDisabled || status === "uploading"}
          onClick={doGenerateLetter}
        >
          Draft
        </Button>
        {status === "uploading" && <Spinner />}
        {status === "error" && (
          <p className="text-red-500">Failed to generate letter.</p>
        )}
      </div>
      <div className="w-full border rounded-sm h-1/4">
        <NewBioEditor
          id={data.baseInputs.beneficiary.id}
          label="Beneficiary"
          type="user"
          onSelect={doSelectBeneficiary}
          key={data.baseInputs.beneficiary.id}
        />
      </div>
      <div className="w-full border rounded-sm h-1/4">
        <NewBioEditor
          id={data.baseInputs.signatory.id}
          label="Signatory"
          type="user"
          onSelect={doSelectSignatory}
          key={data.baseInputs.signatory.id}
        />
      </div>
      <div className="w-full border rounded-sm h-1/4">
        <NewBioEditor
          id={data.baseInputs.company.id}
          label="Beneficiary Company"
          type="company"
          onSelect={doSelectCompany}
          key={data.baseInputs.company.id}
        />
      </div>

      <div className="w-full border rounded-sm">
        <Textarea
          className="min-h-full border-none shadow-none"
          placeholder="How did they meet?"
          defaultValue={data.baseInputs.howTheyMet}
          onChange={(e) =>
            setData({
              ...data,
              baseInputs: {
                ...data.baseInputs,
                howTheyMet: e.target.value,
              },
            })
          }
        />
      </div>
      <div className="w-full border rounded-sm">
        <Textarea
          className="min-h-full border-none shadow-none"
          placeholder="Additional information"
          defaultValue={data.baseInputs.additionalInfo}
          onChange={(e) =>
            setData({
              ...data,
              baseInputs: {
                ...data.baseInputs,
                additionalInfo: e.target.value,
              },
            })
          }
        />
      </div>
    </div>
  );
};

const LetterGenComponent = () => {
  const { generatedLetter } = useContext(DraftContext);

  return (
    <div className="w-full flex flex-row mt-2 h-full gap-x-2">
      <LeftBar />
      <MiddleBar />
      <LetterPreview
        disabled={generatedLetter.trim() === ""}
        letter={generatedLetter}
      />
    </div>
  );
};

const Lettergen = () => {
  const [data, setData] = useState<DraftContextType>({
    baseInputs: {
      letterType: "critical-role",
      visaClass: "",
      howTheyMet: "",
      fieldOfEndeavor: "",
      signatoryEmployer: {
        id: "",
        name: "",
      },
      beneficiary: {
        id: "",
        name: "",
        bio: "",
        title: "",
      },
      signatory: {
        id: "",
        name: "",
        bio: "",
        title: "",
      },
      company: {
        id: "",
        name: "",
        bio: "",
      },
      archetype: "",
      additionalInfo: "",
    },
  });

  const [status, setStatus] = useState<FormStatus>("idle");
  const [generatedLetter, setGeneratedLetter] = useState<string>("");

  return (
    <DraftContext.Provider
      value={{
        data,
        setData,
        status,
        setStatus,
        generatedLetter,
        setGeneratedLetter,
      }}
    >
      <LetterGenComponent />
    </DraftContext.Provider>
  );
};

export default Lettergen;
