import AdminNavigation from "@components/navigation/admin";
import { useContact } from "@utils/hooks";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

const ResearchAgent = () => {
  const { admin } = useContact();
  const navigate = useNavigate();

  useEffect(() => {
    if (!admin) navigate("/404");
  }, [admin, navigate]);

  return (
    <div className="flex flex-col w-screen h-screen flex-grow">
      <AdminNavigation selectedPage="researchagent" />

      <iframe
        src="https://research-agent-docker-c7q1.onrender.com/docs"
        allowFullScreen
        className="flex-1"
      />
    </div>
  );
};

export default ResearchAgent;
