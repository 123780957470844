import { Button } from '@components/button';
import { FormItem, FormLabel, FormMessage } from '@components/form';
import { Input } from '@components/input';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { OnboardingContainer } from '@pages/onboarding/shared/onboarding-container';
import React from 'react';
import { FormProvider, useForm, useFormContext } from 'react-hook-form';
import { OnboardingPageType } from '@pages/onboarding/types';
import { z } from 'zod';
import { zodResolver } from '@hookform/resolvers/zod';
import { useUserUpdatesMutation } from '@codegen/index';
import { useLogError } from '@utils/error';
import { UpdateUserByIdInput } from '@codegen/schema';
import { ErrorMessage } from '@hookform/error-message';
import { FormErrorMessage } from '@pages/onboarding/shared/error';
import Spinner from '@components/spinner';

export const legalNameFormSchema = z.object({
  firstName: z.string().min(1, "Please provide a first name"),
  lastName: z.string().min(1, "Please provide a last name"),
  middleName: z.string().optional(),
});

export type LegalNameFormType = z.infer<typeof legalNameFormSchema>;

export const LegalNameForm: React.FC<OnboardingPageType> = ({ contact, onSubmit, onBack, exhibit }) => {
  const { data }: {
    data: {
      firstName?: string
      lastName?: string
      middleName?: string
    }
  } = exhibit

  const methods = useForm({
    defaultValues: {
      firstName: data.firstName ?? contact.firstName,
      lastName: data.lastName ?? contact.lastName,
      middleName: data.middleName ?? contact.middleName,
    },
    resolver: zodResolver(legalNameFormSchema),
  });
  const { register, formState, setError } = methods
  const logError = useLogError()
  const [userUpdatesMutation, { loading: saving }] = useUserUpdatesMutation()
  const handleSubmit = React.useCallback(async (data: LegalNameFormType) => {
    try {
      const { errors: updateUserError } = await userUpdatesMutation({
        variables: {
          input: {
            id: contact.id,
            userPatch: {
              fullName: data.middleName ? `${data.firstName} ${data.middleName} ${data.lastName}` : `${data.firstName} ${data.lastName}`,
            },
          } as UpdateUserByIdInput,
        },
      });

      if (updateUserError != null) {
        throw updateUserError[0]
      }

      onSubmit(data);
    } catch (exception) {
      if (exception instanceof Error) {
        setError('root.serverError', { 
          type: exception.name,
          message: exception.message
        })
      }
      logError(exception, {
        message: 'Error saving contact',
        data
      })
    }
  }, [userUpdatesMutation, contact, setError])

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={`Hello ${contact?.firstName}! We're excited to get started on your case.`}
        subtitle=""
        cardTitle='Could you please share your full legal name?'
        progress={0}
        onSubmit={handleSubmit}
      >
        <FieldsContainer status={''}>
          <FormItem>
            <FormLabel>First Name</FormLabel>
            <Input {...register('firstName')} placeholder="First Name" />
            <ErrorMessage
              errors={formState.errors}
              name="firstName"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FormItem>
          <FormItem>
            <FormLabel>Middle Name <small>(Optional)</small></FormLabel>
            <Input {...register('middleName')} placeholder="Middle Name" />
            <ErrorMessage
              errors={formState.errors}
              name="middleName"
              render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
            />
          </FormItem>
          <FormItem>
            <FormItem>
              <FormLabel>Last Name</FormLabel>
              <Input {...register('lastName')} placeholder="Last Name" />
              <ErrorMessage
                errors={formState.errors}
                name="lastName"
                render={({ message }) => <FormErrorMessage>{message}</FormErrorMessage>}
              />
            </FormItem>
          </FormItem>
        </FieldsContainer>
        <CTAContainer onBack={onBack}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            disabled={saving || formState.isValidating}
            type="submit"
          >
            {saving ? <Spinner /> : 'Next'}
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
}

export default LegalNameForm;
