import React, { Component } from 'react';
import * as Sentry from '@sentry/react'
import { Link } from 'react-router-dom';

class ErrorBoundary extends Component {
  constructor(props) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error) {
    return { hasError: true };
  }

  componentDidCatch(error, errorInfo) {
    console.error("Uncaught error:", error, errorInfo);
    Sentry.captureException(error, {
      extra: {
        errorInfo
      }
    })
  }

  render() {
    if (this.state.hasError) {
      return (
        <div className="flex w-full h-screen justify-center items-center">
          <div>
            <h1 className="text-2xl mb-3">Something went wrong. Our apology, engineering is on it!</h1>
            <p>Head back <Link className="text-accent" to="/">Home</Link> for now and we'll let you know when it's fixed.</p>
          </div>
        </div>
      );
    }

    return this.props.children;
  }
}

export default ErrorBoundary;
