import {
  BuilderHeaderDocument,
  ExhibitByIdDocument,
  useExhibitByIdQuery,
  useReusableExhibitByIdQuery,
  useUpdateExhibitByIdMutation,
  useUpdateResuableExhibitMutation,
} from "@codegen/index";
import {
  ExhibitByIdQuery,
  ExhibitOwnerType,
  ExhibitStatusType,
  ReusableExhibitByIdQuery,
  UpdateExhibitByIdInput,
} from "@codegen/schema";
import {
  DropdownMenu,
  DropdownMenuContent,
  DropdownMenuItem,
  DropdownMenuTrigger,
} from "@components/dropdownMenu";
import Spinner from "@components/spinner";
import { cn } from "@utils/cn";
import { useMemo } from "react";

export const ExhibitStatusJellyBean = (props: { id: string }) => {
  const styling: Record<ExhibitStatusType, string> = {
    PENDING_DOCUMENTS: "bg-blue-50 text-blue-500",
    DOCUMENT_UPLOADED: "bg-blue-100 text-blue-500",
    DOCUMENT_APPROVAL: "bg-blue-200 text-blue-600",
    CREATE_EXHIBIT_DESCRIPTION: "bg-purple-50 text-purple-500",
    REVIEW_EXHIBIT_DESCRIPTION: "bg-purple-100 text-purple-500",
    APPROVED_EXHIBIT_DESCRIPTION: "bg-purple-200 text-purple-600",
    APPROVED_EXHIBIT: "bg-green-50 text-green-500",
  };

  const unknown = "bg-gray-50 text-gray-500";

  const q = useExhibitByIdQuery({
    variables: {
      id: props.id,
    },
  });

  const [updateExhibitMutation] = useUpdateExhibitByIdMutation();

  const doSetStatus = async (status: ExhibitStatusType) => {
    const { errors } = await updateExhibitMutation({
      variables: {
        input: {
          id: props.id,
          exhibitPatch: {
            status,
          },
        } as UpdateExhibitByIdInput,
      },
      refetchQueries: [ExhibitByIdDocument],
    });

    if (errors != null) {
      console.error(errors);
      return;
    }
  };

  const exhibit = q.data?.exhibitById;

  const fmtStatus = useMemo(() => {
    if (exhibit == null) return "Unknown";
    const res = exhibit.status.replaceAll("_", " ");
    return res.charAt(0).toUpperCase() + res.slice(1).toLowerCase();
  }, [exhibit]);

  return (
    <>
      {q.loading || exhibit == null ? (
        <Spinner />
      ) : (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div
              className={cn(
                "flex text-xs rounded-lg items-center justify-center h-fit p-2 text-semibold hover:cursor-pointer w-1/4",
                styling[exhibit.status] ?? unknown
              )}
            >
              {fmtStatus}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 flex flex-col gap-y-1">
            {Object.keys(styling).map((status, idx) => (
              <DropdownMenuItem
                key={idx}
                className={cn(
                  "py-1 items-center flex w-full justify-center hover:cursor-pointer",
                  styling[status as ExhibitStatusType]
                )}
                onClick={() => doSetStatus(status as ExhibitStatusType)}
              >
                {(
                  status.charAt(0).toUpperCase() + status.slice(1).toLowerCase()
                ).replaceAll("_", " ")}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );
};

export const ExhibitOwnerJellyBean = (props: {
  id: string;
  reusable?: boolean;
}) => {
  const styling: Record<ExhibitOwnerType, string> = {
    BENEFICIARY: "bg-green-50 text-green-500",
    PLYMOUTH: "bg-blue-50 text-blue-500",
    PETITIONER: "bg-yellow-50 text-yellow-500",
  };

  const unknown = "bg-gray-50 text-gray-500";

  const query = props.reusable
    ? useReusableExhibitByIdQuery
    : useExhibitByIdQuery;

  const q = query({
    variables: {
      id: props.id,
    },
  });

  const [updateExhibitMutation] = useUpdateExhibitByIdMutation();
  const [updateReusableExhibitMutation] = useUpdateResuableExhibitMutation();

  const doSetOwner = async (ownedBy: ExhibitOwnerType) => {
    const mutation = props.reusable
      ? updateReusableExhibitMutation
      : updateExhibitMutation;
    const input = props.reusable
      ? {
          id: props.id,
          reusableExhibitPatch: {
            ownedBy,
          },
        }
      : {
          id: props.id,
          exhibitPatch: {
            ownedBy,
          },
        };

    const { errors } = await mutation({
      variables: {
        // @ts-expect-error - TS doesn't like the dynamic nature of this
        input: input,
      },
      refetchQueries: [ExhibitByIdDocument, BuilderHeaderDocument],
    });

    if (errors != null) {
      console.error(errors);
      return;
    }
  };

  const exhibit = props.reusable
    ? (q.data as ReusableExhibitByIdQuery)?.reusableExhibitById
    : (q.data as ExhibitByIdQuery)?.exhibitById;

  return (
    <>
      {q.loading || exhibit == null ? (
        <Spinner />
      ) : (
        <DropdownMenu>
          <DropdownMenuTrigger asChild>
            <div
              className={cn(
                "flex text-[12px] rounded-lg items-center justify-center w-fit h-fit p-2 text-semibold hover:cursor-pointer",
                styling[exhibit.ownedBy] ?? unknown
              )}
            >
              {exhibit.ownedBy.charAt(0).toUpperCase() +
                exhibit.ownedBy.slice(1).toLowerCase()}
            </div>
          </DropdownMenuTrigger>
          <DropdownMenuContent className="w-56 flex flex-col gap-y-1">
            {Object.keys(styling).map((ownedBy, idx) => (
              <DropdownMenuItem
                key={idx}
                className={cn(
                  "py-1 items-center flex w-full justify-center hover:cursor-pointer",
                  styling[ownedBy as ExhibitOwnerType]
                )}
                onClick={() => doSetOwner(ownedBy as ExhibitOwnerType)}
              >
                {ownedBy.charAt(0).toUpperCase() +
                  ownedBy.slice(1).toLowerCase()}
              </DropdownMenuItem>
            ))}
          </DropdownMenuContent>
        </DropdownMenu>
      )}
    </>
  );
};
