import { useAllImmigrationDocsByUserQuery } from '@codegen/index';
import { CompanyDocumentType, ImmigrationDocumentType } from '@codegen/schema';
import { Button } from '@components/button';
import Spinner from '@components/spinner';
import Uploader from '@components/uploader/uploader';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { OnboardingContainer } from '@pages/onboarding/shared/onboarding-container';
import { OnboardingPageType } from '@pages/onboarding/types';
import { api } from '@utils/api';
import { useLogError } from '@utils/error';
import { useJwt } from '@utils/hooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

export const visaFormSchema = z.object({
  visaStamps: z.string().min(1, 'Please upload your visa')
});

export type VisaFormType = z.infer<typeof visaFormSchema>;
export const VisaForm: React.FC<OnboardingPageType> = ({ onSubmit, onBack, onSkip, contact }) => {
  const [visaUploaded, setVisaUploaded] = React.useState(false);
  const logError = useLogError()
  const methods = useForm({
    defaultValues: {
      visaStamps: ''
    }
  });
  const { data: immigrationDocsData, loading } = useAllImmigrationDocsByUserQuery({
    variables: {
      ownerId: contact.id
    }
  })

  const handleSubmit = (data: VisaFormType) => {
    onSubmit(data)
  }
  const token = useJwt();
  const data = {}
  const visaUpload = React.useMemo(() => {
    if (data == null || immigrationDocsData?.allImmigrationDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of immigrationDocsData.allImmigrationDocuments.nodes) {
      if (doc?.fileByFileId?.id == null) {
        continue;
      }

      if (doc.type === ImmigrationDocumentType.VisaStamp) {
        res.push({
          id: doc.fileByFileId.id ?? "",
          name: doc.fileByFileId.name,
        });
      }
    }
    return res;
  }, [data]);

  const onVisaUpload = React.useCallback(
    async (file: File) => {
      try {
        const data = await api.files.uploadImmigrationDoc(
          file,
          token,
          contact.id,
          ImmigrationDocumentType.VisaStamp
        );
        if (data.success) setVisaUploaded(true);
        const { id } = data

        return {
          success: data.success,
          link: "",
          fileId: id
        };
      } catch (exception) {
        logError(exception)
        return {
          success: false,
          link: ""
        }
      }
    },
    [contact.id, token]
  );

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={"Identification information"}
        subtitle={<p>Have any U.S. visa stamps ever been issued in your visa? <b>If yes</b>, please upload copies.</p>}
        cardTitle={"Upload visa stamps"}
        progress={0}
        onSubmit={handleSubmit}
      >
        <FieldsContainer status={''}>
          <div>
            <Button variant="link" className="pl-0">
              Visa stamps
            </Button>
            {visaUpload == null && <Spinner />}
            {visaUpload != null && (
              <Uploader
                multiple
                onFileUpload={onVisaUpload}
                defaultFiles={visaUpload.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
          </div>
        </FieldsContainer>
        <CTAContainer onBack={onBack} onSkip={onSkip}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            // disabled={!formState.isValid || formState.isValidating}
            type="submit"
          >
            Next
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
}

export default VisaForm;
