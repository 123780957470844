import { cn } from "@utils/cn";
import { ReactNode } from "react";
import { useHotkeys } from "react-hotkeys-hook";
import { Key } from "ts-key-enum";

const Modal = ({
  children,
  onClose,
  className,
}: {
  children: ReactNode;
  onClose?: () => void;
  className?: string;
}) => {
  useHotkeys(Key.Escape, () => {
    if (onClose) {
      onClose();
    }
  });

  return (
    <div
      className={cn(
        "fixed inset-0 flex items-center justify-center z-50 pointer-events-auto w-full"
      )}
    >
      <div className="absolute inset-0 bg-charcoal opacity-50"></div>
      <div
        className={cn(
          className,
          "bg-background p-6 rounded-md shadow-lg w-full max-w-3xl relative z-10"
        )}
      >
        {children}
      </div>
    </div>
  );
};

export default Modal;
