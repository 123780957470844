import { EntityTypes } from "@codegen/schema";

export const entityOptions = [
  {
    label: "People",
    value: EntityTypes.User,
  },
  {
    label: "Companies",
    value: EntityTypes.Company,
  },
  {
    label: "Award",
    value: EntityTypes.Award,
  },
  {
    label: "Location",
    value: EntityTypes.Location,
  },
  {
    label: "Role",
    value: EntityTypes.Role,
  },
  {
    label: "Judging Entity",
    value: EntityTypes.JudgingEntity,
  },
  {
    label: "Authorship Publisher",
    value: EntityTypes.AuthorshipPublisher,
  },
  {
    label: "Press Publisher",
    value: EntityTypes.PressPublisher,
  },
  {
    label: "VC",
    value: EntityTypes.Vc,
  },
  {
    label: "Membership",
    value: EntityTypes.Membership,
  },
];
