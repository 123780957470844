import { useEditor } from "@tiptap/react";

import ExtensionKit from "./extensions/extension-kit";

export const useBlockEditor = (props: {
  initialContent?: string;
  onSavedContent?: (content: string) => void;
}) => {
  const editor = useEditor(
    {
      autofocus: true,
      onCreate: ({ editor }) => {
        if (editor.isEmpty) {
          editor.commands.setContent(props.initialContent ?? "");
        }
      },
      extensions: [...ExtensionKit({})],
      editorProps: {
        attributes: {
          autocomplete: "off",
          autocorrect: "off",
          autocapitalize: "off",
          class: "min-h-full",
        },
      },
    },
    []
  );

  const characterCount = editor?.storage.characterCount || {
    characters: () => 0,
    words: () => 0,
  };

  return { editor, characterCount };
};
