import { useAllImmigrationDocsByUserQuery } from '@codegen/index';
import { CompanyDocumentType, ImmigrationDocumentType } from '@codegen/schema';
import { Button } from '@components/button';
import Spinner from '@components/spinner';
import Uploader from '@components/uploader/uploader';
import { CTAContainer } from '@pages/onboarding/shared/cta-container';
import { FieldsContainer } from '@pages/onboarding/shared/fields-container';
import { OnboardingContainer } from '@pages/onboarding/shared/onboarding-container';
import { OnboardingPageType } from '@pages/onboarding/types';
import { api } from '@utils/api';
import { useLogError } from '@utils/error';
import { useJwt } from '@utils/hooks';
import React from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import { z } from 'zod';

export const studentVisaFormSchema = z.object({
  studentVisaStamps: z.string().min(1, 'Please upload your student visa')
});

export type StudentVisaFormType = z.infer<typeof studentVisaFormSchema>;
export const StudentVisaForm: React.FC<OnboardingPageType> = ({ onSubmit, onBack, onSkip, contact }) => {
  const [studentVisaUploaded, setstudentVisaUploaded] = React.useState(false);
  const logError = useLogError()
  const methods = useForm({
    defaultValues: {
      studentVisaStamps: ''
    },
    // resolver: zodResolver(addressSchema),
  });
  const { data: immigrationDocsData, loading } = useAllImmigrationDocsByUserQuery({
    variables: {
      ownerId: contact.id
    }
  })

  const handleSubmit = (data: StudentVisaFormType) => {
    onSubmit(data)
  }
  const token = useJwt();
  const data = {}
  const i20Upload = React.useMemo(() => {
    if (data == null || immigrationDocsData?.allImmigrationDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of immigrationDocsData.allImmigrationDocuments.nodes) {
      if (doc?.fileByFileId?.id == null) {
        continue;
      }

      if (doc.type === ImmigrationDocumentType.I_20) {
        res.push({
          id: doc.fileByFileId.id ?? "",
          name: doc.fileByFileId.name,
        });
      }
    }
    return res;
  }, [data]);

  const onI20Upload = React.useCallback(
    async (file: File) => {
      try {
        const data = await api.files.uploadImmigrationDoc(
          file,
          token,
          contact.id,
          ImmigrationDocumentType.I_20
        );
        if (data.success) setstudentVisaUploaded(true);
        const { id } = data

        return {
          success: data.success,
          link: "",
          fileId: id
        };
      } catch (exception) {
        logError(exception)
        return {
          success: false,
          link: ""
        }
      }
    },
    [contact.id, token]
  );

  const ds2019Upload = React.useMemo(() => {
    if (data == null || immigrationDocsData?.allImmigrationDocuments?.nodes == null) {
      return;
    }

    const res = [];

    for (const doc of immigrationDocsData.allImmigrationDocuments.nodes) {
      if (doc?.fileByFileId?.id == null) {
        continue;
      }

      if (doc.type === ImmigrationDocumentType.Ds_2019) {
        res.push({
          id: doc.fileByFileId.id ?? "",
          name: doc.fileByFileId.name,
        });
      }
    }
    return res;
  }, [data]);

  const onDS2019Upload = React.useCallback(
    async (file: File) => {
      try {
        const data = await api.files.uploadImmigrationDoc(
          file,
          token,
          contact.id,
          ImmigrationDocumentType.Ds_2019
        );
        if (data.success) setstudentVisaUploaded(true);
        const { id } = data

        return {
          success: data.success,
          link: "",
          fileId: id
        };
      } catch (exception) {
        logError(exception)
        return {
          success: false,
          link: ""
        }
      }
    },
    [contact.id, token]
  );

  return (
    <FormProvider {...methods}>
      <OnboardingContainer
        title={"Identification information"}
        subtitle={(
          <p>
            Have any U.S. student visa documents been issued? <b>If yes</b>, please upload copies.
          </p>
        )}
        cardTitle={"Upload student visa stamps"}
        progress={0}
        onSubmit={handleSubmit}
      >
        <FieldsContainer status={''}>
          <div>
            <Button variant="link" className="pl-0">
              I-20
            </Button>
            {i20Upload == null && <Spinner />}
            {i20Upload != null && (
              <Uploader
                multiple
                onFileUpload={onI20Upload}
                defaultFiles={i20Upload.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
          </div>
          <div>
            <Button variant="link" className="pl-0">
              DS-2019
            </Button>
            {ds2019Upload == null && <Spinner />}
            {ds2019Upload != null && (
              <Uploader
                multiple
                onFileUpload={onDS2019Upload}
                defaultFiles={ds2019Upload.map((x) => ({
                  id: x.id ?? "",
                  name: x.name ?? "",
                }))}
              />
            )}
          </div>
        </FieldsContainer>
        <CTAContainer onBack={onBack} onSkip={onSkip}>
          <Button
            variant="accent"
            className="ml-auto text-md rounded-sm px-7 py-5"
            // disabled={!formState.isValid || formState.isValidating}
            type="submit"
          >
            Next
          </Button>
        </CTAContainer>
      </OnboardingContainer>
    </FormProvider>
  );
}

