import BuilderNavigator from "@components/adminPageNavigator";
import LetterGen from "@pages/admin/letterGen";

const Draft = () => {

  return (
    <div className="max-h-screen h-screen w-screen flex flex-col bg-gray-50 p-4 overflow-scroll">
      <BuilderNavigator />
      <LetterGen />
    </div>
  );
}

export default Draft
