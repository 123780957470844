import React, { Suspense, useEffect, useMemo } from "react";
import ReactDOM from "react-dom/client";
import "./index.css";

import { BrowserRouter, Route, Routes, useLocation } from "react-router-dom";
import { Auth0ProviderWithNavigate } from "@providers/auth0";
import { ThemeProvider } from "@providers/themeProvider";

import * as Sentry from "@sentry/react";
import { CaptureConsole } from "@sentry/integrations";
import { useAuth0 } from "@auth0/auth0-react";
import PostgresApolloProvider from "@providers/apollo";
import { useContact } from "@utils/hooks";
import Navbar from "@components/navigation";
import { AuthenticationGuard } from "@components/authGuard";
import Login from "@pages/auth/login";
import Logout from "@pages/auth/logout";
import ResearchAgent from "@pages/admin/researchAgent";
import SuspenseLoader from "@components/suspenseLoader";
import PetitionBuilderHome from "@pages/builder/home";
import PetitionCreator from "@pages/create";
import ExhibitRepo from "@pages/exhibitRepo";
import Draft from "@pages/draft";

const Builder = React.lazy(() => import("@pages/builder"));
const AdminPortal = React.lazy(() => import("@pages/admin/admin"));
const LetterGenerator = React.lazy(() => import("@pages/admin/letterGen"));
const LetterGeneratorEndpoints = React.lazy(
  () => import("@pages/admin/letterGenEndpoints")
);

const ActionItemsPage = React.lazy(
  () => import("@pages/dashboard/action-items")
);
const Entities = React.lazy(() => import("@pages/entities"));
const Home = React.lazy(() => import("@pages/app"));

const DocumentsPage = React.lazy(() => import("@pages/dashboard/documents"));
import { StatsigProvider } from "@statsig/react-bindings";
import { EmployerOnboardingDemo } from "@pages/onboarding/employer-onboarding";
import { Toaster } from "react-hot-toast";
import { useAsyncClient } from "./vendors/statsig";
import { OnboardingWorkflows } from "@pages/onboarding/workflows";

if (import.meta.env.PROD) {
  Sentry.init({
    dsn: "https://43a516e96c9fa93f374e1d1df0360176@o4506147990994944.ingest.sentry.io/4506152682651648",
    integrations: [
      new CaptureConsole({
        levels: ["error"],
      }),
      new Sentry.BrowserTracing({
        // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [
          // "localhost",
          /^https:\/\/portal\.plymouthstreet\.org/,
          /^https:\/\/portal\.plymouthstreet\.com/,
          /^https:\/\/portal-ui\.onrender\.com/,
        ],
        // routingInstrumentation: Sentry.reactRouterV6Instrumentation(
        //   React.useEffect,
        //   useLocation,
        //   useNavigationType,
        //   createRoutesFromChildren,
        //   matchRoutes
        // ),
      }),
      new Sentry.Replay({
        maskAllText: false,
        blockAllMedia: false,
        maskAllInputs: false,
        networkDetailAllowUrls: [
          window.location.origin,
          "https://api.baseql.com",
        ],
      }),
    ],
    // Performance Monitoring
    tracesSampleRate: 1.0, // Capture 100% of the transactions
    // Session Replay
    replaysSessionSampleRate: 1.0, // This sets the sample rate at 100%. You may want to change it to 100% while in development and then sample at a lower rate in production.
    replaysOnErrorSampleRate: 0.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
  });
}

const STATSIG_CLIENT_KEY = import.meta.env.VITE_STATSIG_CLIENT_KEY
const USER_NODE_ENV = import.meta.env.VITE_NODE_ENV

const root = ReactDOM.createRoot(
  document.getElementById("root") as HTMLElement
);

const MainRoutes = () => {
  const location = useLocation();
  const { user } = useAuth0();
  const { contact } = useContact();
  const { statsigClient } = useAsyncClient()

  const navbarVisible = useMemo(() => {
    const navbarVisiblePaths = ["home", "actions", "docs", "help", "track"];

    if (contact?.portalOnboarded == null || contact.portalOnboarded === false) {
      return false;
    }

    const page = location.pathname.split("/")[1];
    if (page === "") {
      return true;
    }

    return navbarVisiblePaths.includes(page);
  }, [contact, location.pathname]);

  useEffect(() => {
    if (user == null) return;

    Sentry.setUser({
      id: user.sub,
      name: user.name,
      email: user.email,
    });
  }, [user]);

  return (
    <StatsigProvider
      loadingComponent={<SuspenseLoader />}
      client={statsigClient}
    >
      <div className="min-h-screen h-full flex flex-row overflow-x-hidden">
        {navbarVisible && (
          <div>
            <Navbar />
          </div>
        )}
        <Toaster position="bottom-right" />
        <div className="w-full h-full">
          <Routes>
            <Route path="/login" Component={Login} />
            <Route path="/logout" Component={Logout} />
            <Route
              path="*"
              Component={() => (
                <div className="flex flex-col min-h-screen w-full justify-center items-center">
                  <div className="text-2xl">🤒</div>
                  <p className="font-thin text-l pt-2">404: Page not found</p>
                  <a href="/">Go Home</a>
                </div>
              )}
            />
            <Route
              path="/admin"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={AdminPortal} />
                </Suspense>
              }
            />
            <Route
              path="/admin/researchagent"
              element={<AuthenticationGuard component={ResearchAgent} />}
            />
            <Route
              path="/admin/new_lettergen"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={LetterGenerator} />
                </Suspense>
              }
            />
            <Route
              path="/admin/lettergen"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={LetterGeneratorEndpoints} />
                </Suspense>
              }
            />
            <Route
              path="/admin/petition-builder"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={PetitionBuilderHome} />
                </Suspense>
              }
            />
            <Route
              path="/admin/petition-builder/new"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={PetitionCreator} />
                </Suspense>
              }
            />
            <Route
              path="/admin/petition-builder/:id"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={Builder} />
                </Suspense>
              }
            />

            <Route
              path="/admin/entities"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={Entities} />
                </Suspense>
              }
            />
            <Route
              path="/admin/exhibit-repo"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={ExhibitRepo} />
                </Suspense>
              }
            />
            <Route
              path="/admin/draft"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={Draft} />
                </Suspense>
              }
            />
            <Route
              path="/"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={Home} />
                </Suspense>
              }
            />
            <Route
              path="/actions"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={ActionItemsPage} />
                </Suspense>
              }
            />
            <Route
              path="/onboarding/*"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={OnboardingWorkflows} />
                </Suspense>
              }
            />
            <Route
              path="/docs"
              element={
                <Suspense fallback={<SuspenseLoader />}>
                  <AuthenticationGuard component={DocumentsPage} />
                </Suspense>
              }
            />
          </Routes>
        </div>
      </div>
    </StatsigProvider>
  );
};

root.render(
  <React.StrictMode>
    <ThemeProvider defaultTheme="light" storageKey="vite-ui-theme">
      <BrowserRouter>
        {/* <SentryRoutes> */}
        <Auth0ProviderWithNavigate>
          <PostgresApolloProvider>
            <MainRoutes />
          </PostgresApolloProvider>
        </Auth0ProviderWithNavigate>
        {/* </SentryRoutes> */}
      </BrowserRouter>
    </ThemeProvider>
  </React.StrictMode>
);
