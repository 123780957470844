import * as React from "react";

import { useAuth0 } from "@auth0/auth0-react";
import { useAsync } from "@react-hook/async";

import useEvent from "@react-hook/event";
import { useNavigate, useLocation } from "react-router-dom";

import { mapMaybe } from "./index";
import {
  MemoryPersistedStorage,
  PersistedStorage,
  localPersistedStorage,
  sessionPersistedStorage,
} from "./persisted";
import { __DEV__ } from "@apollo/client/utilities/globals";
import { parseContact } from "./parsers";
import { PlymouthUser, Team } from "./types";

import { useState } from "react";
import { ApolloError, ApolloQueryResult } from "@apollo/client";
import * as Sentry from "@sentry/browser";
import {
  useGetPetitionsByCompanyIdQuery,
  useGetPetitionsByUserIdQuery,
  useGetUserByIdQuery,
  useLoggedInUserQuery,
} from "@codegen/index";
import {
  GetPetitionsByCompanyIdQuery,
  GetPetitionsByUserIdQuery,
  PetitionFragment,
} from "@codegen/schema";
import { useLogError } from "./error";
const logError = useLogError()
export type UpdaterArg<T> = T | ((old: T) => T);
export type Updater<T> = (x: T | ((old: T) => T)) => void;

declare global {
  interface Window {
    plymouth: any;
  }
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const useSticky = <T extends (...args: any[]) => unknown>(
  f: T,
  delay = 100
): [(...args: Parameters<T>) => void, (...args: Parameters<T>) => void] => {
  const okayToRun = React.useRef<number | undefined>();
  const timeout = React.useRef<NodeJS.Timeout | undefined>();
  const timeoutF = React.useRef<() => unknown>();

  const stick = React.useCallback(
    (...args: Parameters<T>) => {
      if (timeout.current !== undefined) clearTimeout(timeout.current);

      okayToRun.current = Date.now() + delay;

      f(...args);
    },
    [okayToRun, delay, f]
  );
  const release = React.useCallback(
    (...args: Parameters<T>) => {
      if (okayToRun.current !== undefined) {
        // if not ever stuck, release no matter what
        const dt = okayToRun.current - Date.now();
        if (dt > 0) {
          timeoutF.current = () => f(...args);
          timeout.current = setTimeout(release, dt, ...args);
          return;
        }
      }

      f(...args);
    },
    [okayToRun, f]
  );

  React.useEffect(() => {
    return () => {
      if (timeout.current === undefined) return;
      // there might be a persisted action of some kind scheduled,
      // execute it now since the component is about to unmount and lose all state
      if (timeoutF.current !== undefined) timeoutF.current();
      clearTimeout(timeout.current);
    };
  }, []);

  return [stick, release];
};

export const useStickyButton = <T extends HTMLElement>(
  ref: React.RefObject<T>,
  delay = 100
): boolean => {
  const [isPressed, setIsPressed] = React.useState(false);
  const [stick, release] = useSticky(setIsPressed, delay);

  useEvent(
    ref.current,
    "mousedown",
    React.useCallback(
      (e: MouseEvent) => {
        if (e.button !== 0) return;
        stick(true);
      },
      [stick]
    )
  );
  useEvent(
    document,
    "mouseup",
    React.useCallback(
      (e: MouseEvent) => {
        if (e.button !== 0) return;
        release(false);
      },
      [release]
    )
  );

  return isPressed;
};

export const useTimeout = (delay: number, startTimeout = true): boolean => {
  const [timedout, setTimeoutState] = React.useState(false);
  const timeoutId = React.useRef<NodeJS.Timeout | undefined>();

  React.useEffect(() => {
    if (!startTimeout) {
      if (timeoutId.current !== undefined) clearTimeout(timeoutId.current);
      return;
    }

    const timeoutN = setTimeout(setTimeoutState, delay, true);
    timeoutId.current = timeoutN;
    return () => clearTimeout(timeoutN);
  }, [delay, startTimeout]);

  return timedout;
};

type DropFirst<T extends unknown[]> = T extends [any, ...infer U] ? U : never;
type DropFirstFunction<F extends (...args: any[]) => any> = (
  ...args: DropFirst<Parameters<F>>
) => ReturnType<F>;

export const useCallbackMultiple = <
  F extends (key: any, ...args: any[]) => any
>(
  f: F,
  deps: React.DependencyList
): ((key: Parameters<F>[0]) => DropFirstFunction<F>) => {
  const storeRef = React.useRef<Map<Parameters<F>[0], DropFirstFunction<F>>>(
    new Map()
  );

  React.useEffect(() => {
    storeRef.current = new Map();
  }, deps);

  return React.useCallback(
    (k: Parameters<F>[0]) => {
      const store = storeRef.current;
      const oldF = store.get(k);
      if (oldF !== undefined) return oldF;

      const newF = f.bind(undefined, k);
      store.set(k, newF);
      return newF;
    },
    [f]
  );
};

let didWarnUncontrolledToControlled = false;

export const useControlled = <T>(
  controlled: T | undefined,
  cb: ((x: T) => unknown) | undefined,
  initial: T
): [T, (x: T | ((x: T) => T)) => void] => {
  const { current: isControlled } = React.useRef(controlled !== undefined);

  if (
    !didWarnUncontrolledToControlled &&
    isControlled !== (controlled !== undefined)
  ) {
    didWarnUncontrolledToControlled = true;
    console.error(
      [
        "A component is changing an uncontrolled input to be controlled.",
        "This is likely caused by the value changing from undefined to a defined value, which should not happen.",
        "Decide between using a controlled or uncontrolled input element for the lifetime of the component.",
      ].join(" ")
    );
  }

  const [internalVal, setInternalVal] = React.useState<T>(initial);

  const doSetInternal = React.useCallback(
    (x: UpdaterArg<T>) => {
      if (isControlled) return;

      setInternalVal((v) => {
        const res = x instanceof Function ? x(v) : x;

        if (cb !== undefined) cb(res);
        return res;
      });
    },
    [isControlled, cb]
  );
  const doCallback = React.useCallback(
    (x: UpdaterArg<T>) => {
      if (!isControlled || controlled === undefined) return;
      if (cb === undefined) return;

      if (x instanceof Function) cb(x(controlled));
      else cb(x);
    },
    [isControlled, controlled, cb]
  );

  if (controlled === undefined) return [internalVal, doSetInternal];
  return [controlled, doCallback];
};

export const useIsMounted = (): (() => boolean) => {
  const isMounted = React.useRef<boolean>(true);
  React.useEffect(() => {
    return () => {
      isMounted.current = false;
    };
  }, []);
  return React.useCallback(() => isMounted.current, []);
};

export const identity = <T>(x: T): T => x;

const tryUnmarshall =
  <S, K extends keyof S, T = S[K]>(
    key: K,
    def: T,
    unmarshall: (x: S[K]) => T
  ) =>
  (x: S[K]): T | undefined => {
    try {
      return unmarshall(x);
    } catch (error) {
      console.log(`Failed to unmarshall persisted key ${String(key)}`);
      console.log(error);
      console.log(`Using default value ${def}`);
      return;
    }
  };

export const usePersistedStorageStateMarshalled = <
  S,
  K extends keyof S,
  T = S[K]
>(
  storage: PersistedStorage<S>,
  key: K,
  rawDef: T,
  unmarshall: (x: S[K]) => T,
  marshall: (x: T) => S[K]
): [T, (x: T | ((x: T) => T)) => void] => {
  const defRef = React.useRef<[T, boolean]>([rawDef, false]);
  const {
    current: [def, printedDefChangedError],
  } = defRef;
  if (__DEV__) {
    // eslint-disable-next-line react-hooks/rules-of-hooks
    React.useEffect(() => {
      if (rawDef === def) return;
      if (
        typeof def === typeof rawDef &&
        ["number", "string", "boolean", "symbol", "undefined"].includes(
          typeof def
        )
      )
        return;
      if (printedDefChangedError) return;

      console.error(
        `usePersistedStorageStateMarshalled non-primitive default value changed ${JSON.stringify(
          def
        )} -> ${JSON.stringify(
          rawDef
        )}. This is very likely to cause a retry loop. Wrap the value in a useConst`
      );
      defRef.current[1] = true;
    }, [def, printedDefChangedError, rawDef]);
  }

  const rawItem = storage.getItem(key);
  const stored = mapMaybe(rawItem, tryUnmarshall(key, def, unmarshall));
  if (rawItem != null && stored == null) storage.removeItem(key);

  const [val, setVal] = React.useState<T>(stored ?? def);

  React.useEffect(() => {
    const cb = (k: keyof S, value: S[K] | null) => {
      if (k !== key) return;

      const val = mapMaybe(value, tryUnmarshall(key, def, unmarshall)) ?? def;
      setVal(val);
    };
    storage.listen(cb);

    return () => storage.unlisten(cb);
  }, [key, storage, unmarshall, def]);

  return [
    val,
    React.useCallback(
      (x) => {
        const val =
          mapMaybe(storage.getItem(key), tryUnmarshall(key, def, unmarshall)) ??
          def;
        const res = x instanceof Function ? x(val) : x;

        storage.setItem(key, marshall(res));
      },
      [key, def, storage, marshall, unmarshall]
    ),
  ];
};

export const usePersistedStorageState = <S, K extends keyof S>(
  storage: PersistedStorage<S>,
  key: K,
  def: S[K]
): [S[K], (x: S[K] | ((x: S[K]) => S[K])) => void] =>
  usePersistedStorageStateMarshalled<S, K, S[K]>(
    storage,
    key,
    def,
    identity,
    identity
  );

export const useSessionStorageState = <T>(
  key: string,
  def: T,
  unmarshall: (x: string) => T = JSON.parse,
  marshall: (x: T) => string = JSON.stringify
): [T, (x: T | ((x: T) => T)) => void] =>
  usePersistedStorageStateMarshalled(
    sessionPersistedStorage,
    key,
    def,
    unmarshall,
    marshall
  );

// https://usehooks-typescript.com/react-hook/use-interval
export const useInterval = (callback: () => void, delay: number | null) => {
  const savedCallback = React.useRef(callback);

  // Remember the latest callback if it changes.
  React.useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  React.useEffect(() => {
    // Don't schedule if no delay is specified.
    if (delay === null) {
      return;
    }

    const id = setInterval(() => savedCallback.current(), delay);

    return () => clearInterval(id);
  }, [delay]);
};

// https://usehooks-ts.com/react-hook/use-debounce
export function useDebounce<T>(value: T, delay?: number): T {
  const [debouncedValue, setDebouncedValue] = useState<T>(value);

  React.useEffect(() => {
    const timer = setTimeout(() => setDebouncedValue(value), delay || 500);

    return () => {
      clearTimeout(timer);
    };
  }, [value, delay]);

  return debouncedValue;
}

export const useAccountId = (): [string | undefined, boolean] => {
  const { data, error } = useLoggedInUserQuery({ variables: {} });

  if (error != null) {
    logError(error.graphQLErrors?.[0] ?? error.networkError, {
      message: 'useAccountId > useLoggedInUserQuery error'
    });
  }

  return [
    (data?.currentUserId as string | undefined) ?? undefined,
    (data?.isAdmin as boolean | undefined) ?? false,
  ];
};

export const useLoggedInUser = () => {
  const [userId, idAdmin] = useAccountId()
  const { data } = useGetUserByIdQuery({
    variables: {
      userId,
    }
  })

  const user = React.useMemo(() => {
    const u = data?.userById
    return u
  }, [userId, data])

  return user
}

const accountIdStorage = new MemoryPersistedStorage<{
  viewAccountId: string;
}>();
export const useStoredAccountId = (): [string, Updater<string>] => {
  const res = usePersistedStorageState(
    accountIdStorage,
    "viewAccountId",
    localStorage.getItem("viewAccountId") ?? ""
  );
  React.useEffect(() => {
    if (accountIdStorage.getItem("viewAccountId") == null)
      accountIdStorage.setItem("viewAccountId", res[0]);
    localStorage.setItem("viewAccountId", res[0]);
  }, [res]);

  return res;
};

const teamStorage = new MemoryPersistedStorage<{
  viewTeamId: string;
}>();
export const useStoredTeam = (): [string, Updater<string>] => {
  const res = usePersistedStorageState(
    teamStorage,
    "viewTeamId",
    localStorage.getItem("viewTeam") ?? ""
  );
  React.useEffect(() => {
    if (teamStorage.getItem("viewTeamId") == null)
      teamStorage.setItem("viewTeamId", res[0]);
    localStorage.setItem("viewTeam", res[0]);
  }, [res]);

  return res;
};

const getEffectiveId = (
  accId: string | undefined,
  storedAccId: string,
  userIsAdmin: boolean
): string | undefined => {
  if (accId == null) {
    if (userIsAdmin) {
      return storedAccId;
    }
    return;
  }

  const id =
    storedAccId == null || storedAccId === "undefined" || storedAccId === ""
      ? accId
      : storedAccId;

  return id;
};

export const useContact = (): {
  contact: PlymouthUser | undefined;
  team: Team | undefined;
  teams: Team[] | undefined;
  admin: boolean;
  setStoredAccId: Updater<string>;
  setStoredAccIdNoRedirect: Updater<string>;
  setStoredTeam: Updater<string>;
  loading: boolean;
} => {
  const nav = useNavigate();
  const { getAccessTokenSilently } = useAuth0();

  const [storedAccId, setStoredAccIdRaw] = useStoredAccountId();
  const [storedTeamId, setStoredTeamIdRaw] = useStoredTeam();
  const [accId, userIsAdmin] = useAccountId();
  const [parsedContact, setParsedContact] = useState<
    PlymouthUser | undefined
  >();

  const [parsing, setParsing] = useState(false);

  const effectiveId = getEffectiveId(accId, storedAccId, userIsAdmin);

  Sentry.addBreadcrumb({
    category: "useContact",
    message: `using effective id ${effectiveId} (accId: ${accId}, storedAccId: ${storedAccId}, userIsAdmin: ${userIsAdmin})`,
    level: "info",
  });

  const { data, loading, error } = useGetUserByIdQuery({
    variables: {
      userId: effectiveId ?? "",
    },
    skip: !effectiveId,
  });

  if (error != null) {
    logError(error?.graphQLErrors?.[0], {
      message: "error fetching contact"
    });
  }

  const setStoredAccIdNoRedirect: Updater<string> = React.useCallback(
    (x) => {
      setStoredAccIdRaw(x.toString());
    },
    [setStoredAccIdRaw]
  );

  const setStoredAccId: Updater<string> = React.useCallback(
    (x) => {
      setStoredAccIdNoRedirect(x);
      nav("/");
    },
    [setStoredAccIdNoRedirect, nav]
  );

  const setStoredTeamNoRedirect: Updater<string> = React.useCallback(
    (x) => {
      setStoredTeamIdRaw(x);
    },
    [setStoredTeamIdRaw]
  );

  const setStoredTeam: Updater<string> = React.useCallback(
    (x) => {
      setStoredTeamNoRedirect(x);
      nav("/");
    },
    [nav, setStoredTeamNoRedirect]
  );

  React.useEffect(() => {
    const user = data?.userById;
    if (user == null) return;

    setParsing(true);
    const parsed = parseContact(user);
    setParsing(false);
    setParsedContact(parsed);
  }, [data]);

  React.useEffect(() => {
    if (parsedContact == null) return;

    const firstTeam = parsedContact.teams[0]?.teams[0];
    if (firstTeam == null) return;

    if (storedTeamId === "" || storedTeamId == null) {
      setStoredTeamIdRaw(firstTeam.value.toString());
      return;
    }

    const possibleIds = [
      parsedContact.id,
      ...parsedContact.teams.flatMap((x) => x.teams.flatMap((x) => x.value)),
    ];
    if (possibleIds.includes(storedTeamId)) return;
    setStoredTeamIdRaw(firstTeam.value.toString());
  }, [data, parsedContact, setStoredTeam, setStoredTeamIdRaw, storedTeamId]);

  
  /**
   * `team` === selected team shown on portal
   * This is fluid depends on user selecting which team to view
   */
  const team = React.useMemo(() => {
    if (parsedContact == null) return;

    const team = storedTeamId
      ? parsedContact.teams
          .flatMap((x) => x.teams)
          .find((x) => x.value === storedTeamId)
      : undefined;

    if (team == null) {
      Sentry.addBreadcrumb({
        category: "useContact",
        message: `team ${storedTeamId} not found in contact ${parsedContact.id}, defaulting to first team/personal`,
        level: "info",
      });

      const team = parsedContact.teams[0].teams[0] ?? {
        label: parsedContact.name,
        value: accId,
        type: "personal",
      };

      return team;
    }

    return team;
  }, [accId, parsedContact, storedTeamId]);

  Sentry.setTags({
    accountId: accId ?? "n/a",
    teamId: team?.value ?? "n/a",
  });

  window.plymouth = {
    ...window.plymouth,
    accId,
    wsId: storedAccId === "" ? accId : storedAccId,
    team,
    testSentry: () => {
      breakfn();
    }, // eslint-disable-line @typescript-eslint/no-unsafe-call

    overrideWsId: (x: string) => {
      setStoredAccId(x);
      localPersistedStorage.setItem("overridingWsId", String(x));
    },
    clearWsIdOverride: () => {
      localPersistedStorage.removeItem("viewAccountId");
      localPersistedStorage.removeItem("overridingWsId");
    },
    overrideTeam: (x: string) => {
      setStoredTeam(x);
    },
    clearTeamOverride: () => {
      localPersistedStorage.removeItem("viewTeam");
    },
    getToken: async () => {
      const x = await getAccessTokenSilently();
      console.log(x);
    },
  };
  return {
    loading: loading || parsing,
    contact: parsedContact,
    team: team,
    teams: parsedContact?.teams?.find(t => t.label !== 'Personal')?.teams,
    admin: userIsAdmin,
    setStoredAccId: setStoredAccId,
    setStoredAccIdNoRedirect: setStoredAccIdNoRedirect,
    setStoredTeam: setStoredTeam,
  };
};

const queryMap = {
  personal: useGetPetitionsByUserIdQuery,
  team: useGetPetitionsByCompanyIdQuery,
};

export const usePetitions = (): {
  error?: ApolloError;
  loading: boolean;
  data?: {
    latestPetition: PetitionFragment | null;
    petitions: PetitionFragment[];
  };
  refetch: () => Promise<ApolloQueryResult<GetPetitionsByUserIdQuery>>;
} => {
  const { contact, team } = useContact();
  const query = queryMap[team?.type ?? "personal"];

  const teamInContact =
    team != null && contact != null
      ? contact.teams
          .flatMap((x) => x.teams)
          .find((x) => x.value === team.value)
      : undefined;

  const id =
    teamInContact == null
      ? contact?.teams[0].teams[0].value
      : teamInContact.value;

  const { data, loading, error, refetch } = query({
    variables: {
      id: id ?? "",
    },
    skip: id == null,
    pollInterval: 10000,
  });

  const res = React.useMemo(() => {
    if (data == null) return;
    if (team == null) return;

    if (team.type === "personal") {
      const d = data as GetPetitionsByUserIdQuery;
      if (d == null) return;

      const petitions = d.allPetitions?.nodes ?? [];

      const nonNullPetitions = [];

      for (const x of petitions) {
        if (x == null) continue;
        nonNullPetitions.push(x);
      }

      const latestPetition =
        nonNullPetitions.filter((x) => x.latestPetition)[0] ??
        nonNullPetitions[0];

      return {
        latestPetition,
        petitions: nonNullPetitions,
      };
    }
    const d = data as GetPetitionsByCompanyIdQuery;
    if (d == null) return;
    if (d.allPetitions?.nodes == null) return;
    if (d.allPetitions.nodes.length === 0) return;

    return {
      latestPetition: null,
      petitions: d.allPetitions.nodes.filter(
        (x) => x != null
      ) as PetitionFragment[],
    };
  }, [data, team]);

  return {
    data: res,
    loading,
    error,
    refetch,
  };
};

export const useQueryParams = () => {
  const { search } = useLocation();
  return React.useMemo(() => new URLSearchParams(search), [search]);
};

export const useConst = <T>(x: T): T => {
  const data = React.useRef(x);
  return data.current;
};

export const useJwt = () => {
  const auth0 = useAuth0();
  const [res, callback] = useAsync(auth0.getAccessTokenSilently);

  React.useEffect(() => {
    if (!auth0.isAuthenticated) return;

    callback({});
  }, [auth0, callback]);

  return mapMaybe(res.value, (x) => x) as string;
};

export const useFlag = (
  def = false
): [
  boolean,
  {
    t: () => void;
    f: () => void;
    set: Updater<boolean>;
    toggle: () => void;
  }
] => {
  const [x, setX] = React.useState(def);

  const setFalse = React.useCallback(() => {
    setX(false);
  }, [setX]);
  const setTrue = React.useCallback(() => {
    setX(true);
  }, [setX]);

  const toggle = React.useCallback(() => {
    setX((val) => !val);
  }, []);

  return [
    x,
    {
      t: setTrue,
      f: setFalse,
      set: setX,
      toggle,
    },
  ];
};
