import { useMemo } from "react";
import { Combobox } from "./combobox";
import { useEntitiesByTypeQuery } from "@codegen/index";
import { EntityTypes } from "@codegen/schema";

const EntitySelector = (props: {
  selectedId?: string;
  type: EntityTypes;
  onSelect?: (id: string) => void;
  className?: string;
}) => {
  const { selectedId, onSelect } = props;
  const { data } = useEntitiesByTypeQuery({
    variables: {
      type: props.type,
    },
  });

  const opts = useMemo(() => {
    const opts = [];

    for (const entity of data?.allAdditionalEntities?.nodes ?? []) {
      if (entity == null) continue;
      opts.push({
        value: entity.id ?? "",
        label: entity.name ?? "",
      });
    }
    return opts;
  }, [data?.allAdditionalEntities?.nodes]);

  return (
    <Combobox
      options={opts}
      defaultValue={(selectedId as string) ?? ""}
      onSelect={(e) => onSelect?.(e)}
      placeholder={`Search entity: ${props.type}`}
      className={props.className}
    />
  );
};

export default EntitySelector;
